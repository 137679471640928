(function () {
	'use strict';
	angular
		.module('app')
		.controller('ConfigCtrl', [
			'$rootScope',
			'$scope',
			'$ngSilentLocation',
			'$translate',
			'$routeParams',
			'utilities',
			'$timeout',
			'$location',
			'seedcodeCalendar',
			'manageConfig',
			'manageSettings',
			'daybackIO',
			'manageEventSources',
			'hash',
			'manageTranslations',
			'manageTheme',
			'accountSettings',
			'environment',
			ConfigCtrl,
		])
		.controller('ParentSourcePopoverCtrl', [
			'$scope',
			'$rootScope',
			ParentSourcePopoverCtrl,
		]);

	function ParentSourcePopoverCtrl($scope, $rootScope) {
		$scope.broadcastAction = broadcastAction;

		function broadcastAction(action, source, callback) {
			$scope.popover.config.show = false;
			$rootScope.$broadcast('parentSourcePopoverEdit', {
				action: action,
				source: source,
				callback: callback,
			});
		}
	}

	function ConfigCtrl(
		$rootScope,
		$scope,
		$ngSilentLocation,
		$translate,
		$routeParams,
		utilities,
		$timeout,
		$location,
		seedcodeCalendar,
		manageConfig,
		manageSettings,
		daybackIO,
		manageEventSources,
		hash,
		manageTranslations,
		manageTheme,
		accountSettings,
		environment
	) {
		var preventLocationChangeTrigger;
		var fromLocationChange;
		var selectedEntity;
		var selectedAction;
		var selectedSection;

		var slashReplacementString = '--';
		var primaryGroupName;

		$scope.settings = {};
		$scope.settings.config = seedcodeCalendar.get('config');
		$scope.settings.sourceTypes = manageEventSources.getTemplates();
		$scope.settings.adminSettings = manageConfig.transformSettings();
		$scope.settings.sources = seedcodeCalendar.get('sources');
		$scope.settings.resources = getResources();
		$scope.hash = hash.get();
		$scope.user = manageConfig.getUserData();
		$scope.settings.groupMembers = [];

		$scope.platform = utilities.getDBKPlatform();
		$scope.isFMPlatform = utilities.isFMPlatform();
		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';

		$scope.settings.css = manageTheme.getThemeStyles();
		$scope.settings.themeNotSaved = isThemeEdited();

		$scope.subscriptionURL = hash.getManageURL();
		$scope.purchaseURL = hash.getPurchaseURL($scope.isFileMakerWebViewer);

		$scope.timeList = utilities.generateTimeList(
			seedcodeCalendar.get('config').timeFormat
		);

		$scope.generateFieldMap = generateFieldMap;

		$scope.$on('groupName', ($event, groupName) => {
			// assign fetched group name
			primaryGroupName = groupName;
		});

		$scope.$on('$locationChangeSuccess', function () {
			if (preventLocationChangeTrigger) {
				preventLocationChangeTrigger = null;
				return;
			}
			// Run routine routine to set state from back button
			restoreState();
		});

		//Subscribe to activation so we can update the ui on successful activation
		$scope.$on('activation', function () {
			$scope.hash = hash.get();
			$scope.subscriptionURL = hash.getManageURL();
			$scope.purchaseURL = hash.getPurchaseURL(
				$scope.isFileMakerWebViewer
			);
			getGroupMembers();
		});

		//Subscribe to parent source updated so we can update the ui
		$scope.$on('parentSourcePopoverEdit', function (event, data) {
			var sourceTemplate = data.source.sourceTemplate;

			if (data.action === 'delete') {
				utilities.showModal(
					'<span style="font-style: italic">Warning</span>',
					'This will permanently delete all customizations, including calendar colors, custom actions, and custom summaries. This cannot be undone.',
					'Cancel',
					null,
					'Delete',
					function () {
						deleteSource(data.source, true, data.callback);
					},
					null,
					null,
					true
				);
			} else if (data.action === 'deauthorize') {
				sourceTemplate.deauthFunction(
					function () {
						//Update our display
						$scope.settings.selectedSource = null;
						$scope.settings.isAuthed = false;
						updateSelectedSourceData(sourceTemplate, data.callback);
					},
					false,
					sourceTemplate
				);
			} else if (data.action === 'switchAccount') {
				$scope.settings.selectedSource = null;
				$scope.settings.authChecked = false;
				sourceTemplate.switchAccount(function (result) {
					$scope.$evalAsync(function () {
						//Update our display
						$scope.settings.isAuthed = result;
						$scope.settings.authChecked = true;
						if (result) {
							updateSelectedSourceData(
								sourceTemplate,
								data.callback
							);
						}
					});
				}, sourceTemplate);
			}
		});

		// Check if we are in static data mode
		if (
			_CONFIG.DBK_USE_STATIC_DATA ||
			seedcodeCalendar.get('useStaticData')
		) {
			window.setTimeout(function () {
				utilities.showModal(
					'Administrator Settings Unavailable',
					'Settings are not currently editable. You will be redirected back to the calendar.',
					null,
					null,
					'OK',
					function () {
						$location.path('/');
					}
				);
			}, 750);
		}

		//Retrieve group members
		getGroupSettings();
		getGroupMembers();

		//Retrieve calendar actions
		getCalendarActions();

		//Initialize sidebar scroll - ToDo: watch for some trigger other than just waiting an arbitrary time
		$timeout(function () {
			$scope.$broadcast('addScroll');
		}, 500);

		//Load Translations
		$scope.settings.languages = getLanguages();
		$scope.settings.selectedLanguage =
			$scope.settings.config.language === 'auto'
				? $translate.preferredLanguage()
				: $scope.settings.config.language;

		$scope.settings.translations = selectLanguageTranslation(
			$scope.settings.selectedLanguage
		);
		restoreState();
		function restoreState() {
			//Activate default selection
			var urlParams = getURLParams();
			var action =
				urlParams && urlParams.action ? urlParams.action : null;
			var entity =
				urlParams && urlParams.entity ? urlParams.entity : null;
			var section = $routeParams.section
				? $routeParams.section.replace(slashReplacementString, '/')
				: '';
			var type = $routeParams.type
				? $routeParams.type.replace(slashReplacementString, '/')
				: '';
			var category = $routeParams.category
				? $routeParams.category.replace(slashReplacementString, '/')
				: '';
			var item = $routeParams.item
				? $routeParams.item.replace(slashReplacementString, '/')
				: '';

			fromLocationChange = true;

			if (type) {
				selectCategory(null, category);
				switch (category) {
					case 'account':
						selectAccountType(null, type);
						break;
					case 'source':
						if (entity) {
							selectedEntity = entity;
						}
						if (action) {
							selectedAction = action;
						}
						if (section) {
							selectedSection = section;
						}

						selectSourceType(null, Number(type), item);
						//Run any actions specified in url parameters
						if (action === 'create' && entity === 'calendar') {
							// clear selected entity as we don't want to navigate to this tab
							selectedEntity = null;
							//new calendar - Call async so the previews selection has time to complete fully first
							window.setTimeout(function () {
								addSource(Number(type));
							}, 0);
						}
						break;
					case 'setting':
						selectAdminSetting(null, type);
						break;
					default:
						selectAdminSetting(null, type, category);
						break;
				}
			} else if (!environment.isPhone) {
				selectCategory(null, 'account');
				if ($scope.settings.config.admin) {
					selectAccountType(null, 'setup');
				} else if (!environment.isPhone) {
					selectCategory(null, 'account');
					if ($scope.settings.config.admin) {
						selectAccountType(null, 'setup');
					} else {
						selectAccountType(null, 'user');
					}
				}
			}

			fromLocationChange = null;
		}

		//Clicking on the parent calendar settings menu
		$scope.parentSourceSettings = function (e, calendar) {
			var config = seedcodeCalendar.get('config');
			e.stopPropagation();

			if (config.preventPopover) {
				config.preventPopover = false;
				return;
			}

			var template =
				environment.isPhone && window.innerHeight > window.innerWidth
					? '../../mobile/settings/source/source-settings'
					: 'source-settings';
			var source = {
				id: calendar.id,
				sourceTypeID: calendar.sourceTypeID,
				name: calendar.name,
				sourceTemplate: calendar.sourceTemplate,
			};

			var targetElementRect = e.currentTarget.getBoundingClientRect();

			var popover = {
				controller: 'ParentSourcePopoverCtrl',
				target: e.currentTarget,
				container: environment.isPhone
					? $('.secondColumnInner')
					: $('body'),
				type: 'popover', // modal or popover
				destroyOnScroll: environment.isPhone ? false : true,
				direction: 'auto',
				// width: 250,
				positionX: targetElementRect.right - 15,
				positionY: targetElementRect.top + targetElementRect.height / 2,
				data: source,
				dataTitle: 'source',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/settings/source/' +
					template +
					'.html\'"></div>'
			);
		};

		function getURLParams() {
			var preventTriggerSet;

			var action = $location.search()['action']; //Action could be create, delete, edit
			var entity = $location.search()['entity']; //entity could be calendar, customField, customButtom

			preventLocationChangeTrigger = true;

			$location.search('action', null).replace();
			$location.search('entity', null).replace();

			return {action: action, entity: entity};
		}

		//% complete for icon circle progress
		$scope.$watch(
			function () {
				return (
					$scope.settings.sources.length +
					$scope.settings.groupMembers.length
				);
			},
			function (newValue, oldValue) {
				updatePercentComplete();
			}
		);

		$scope.selectCategory = selectCategory;

		$scope.updateSetting = manageConfig.updateSetting;

		$scope.updateGroupSetting = updateGroupSetting;

		$scope.addEventAction = addEventAction;

		$scope.deleteEventAction = deleteEventAction;

		$scope.addCustomAction = addCustomAction;

		$scope.addCustomField = addCustomField;

		$scope.addContactObjects = addContactObjects;

		$scope.addProjectObjects = addProjectObjects;

		$scope.addResourceObjects = addResourceObjects;

		$scope.addCalendarAction = addCalendarAction;

		$scope.deleteCalendarAction = deleteCalendarAction;

		$scope.deleteCustomField = deleteCustomField;

		$scope.deleteCustomAction = deleteCustomAction;

		$scope.deleteContactObjects = deleteContactObjects;

		$scope.deleteProjectObjects = deleteProjectObjects;

		$scope.deleteResourceObjects = deleteResourceObjects;

		$scope.updateCalendarAction = updateCalendarAction;

		$scope.addGroupMember = addGroupMember;

		$scope.updateGroupMember = updateGroupMember;

		$scope.updateGroupAuthSources = updateGroupAuthSources;

		$scope.updateUserGroupAuthSources = updateUserGroupAuthSources;

		$scope.deleteGroupMember = deleteGroupMember;

		$scope.selectGroupMember = selectGroupMember;

		$scope.addSource = addSource;

		$scope.deleteSource = deleteSource;

		$scope.updateSource = updateSource;

		$scope.sourceSettingExit = sourceSettingExit;

		$scope.toggleField = toggleField;

		$scope.updateField = updateField;

		$scope.toCalendar = toCalendar;

		$scope.authFunction = authFunction;

		$scope.selectAccountType = selectAccountType;

		$scope.selectSourceType = selectSourceType;

		$scope.selectAdminSetting = selectAdminSetting;

		$scope.selectSource = selectSource;

		$scope.selectLanguageTranslation = selectLanguageTranslation;

		$scope.updateTranslation = updateTranslation;

		$scope.updateTheme = updateTheme;

		$scope.saveTheme = saveTheme;

		$scope.testConfig = testConfig;

		$scope.testSalesForceObject = testSalesForceObject;

		$scope.setFileMakerTable = setFileMakerTable;

		$scope.fileMakerLayoutTable = fileMakerLayoutTable;

		$scope.testFileMakerJSFieldMapping = testFileMakerJSFieldMapping;

		$scope.testFileMakerContactData = testFileMakerContactData;

		$scope.testFileMakerProjectData = testFileMakerProjectData;

		$scope.optionsByField = optionsByField;

		$scope.fmJSOptionsByField = fmJSOptionsByField;

		$scope.customFieldPickerSF = customFieldPickerSF;

		$scope.updateCustomFieldDataFormat = updateCustomFieldDataFormat;

		$scope.changeCustomFieldFormat = changeCustomFieldFormat;

		$scope.settingsTab = settingsTab;

		$scope.changeSidebarPanel = changeSidebarPanel;

		$scope.toggleSourceTypeEnabled = toggleSourceTypeEnabled;

		$scope.toggleAutomaticLicenseManagement =
			toggleAutomaticLicenseManagement;

		$scope.manageTokens = manageTokens;

		$scope.getFieldMapItem = manageConfig.getFieldMapItem;

		$scope.copySuccess = function (e) {
			e.clearSelection();
			utilities.showMessage('Copied to clipboard');
		};

		$scope.copyError = function (e) {
			utilities.showMessage('There was a problem copying the user token');
		};

		$scope.toggleMemberActive = function (member, members) {
			var config = seedcodeCalendar.get('config');
			var activeMembers = manageConfig.getActiveMemberCount(members);
			var count = activeMembers;

			if (!member.active && !activeMembers) {
				member.active = true;
				utilities.showMessage(
					'There needs to be at least one active member'
				);
				return;
			}

			if (
				config.manageUserCount &&
				(member.userID || hashData.type === 'trial')
			) {
				manageConfig.adjustUserLicenseCount(
					count,
					applyToggleMemberActive
				);
			} else {
				applyToggleMemberActive(true);
			}

			function applyToggleMemberActive(result) {
				if (!result) {
					$scope.$evalAsync(function () {
						member.active = !member.active;
					});
					return;
				}
				var hashData = hash.get();
				var usersPermitted = hashData.userLimit;

				var inactiveCount = 0;
				var usersPermittedDiff;

				updateGroupMember(
					members,
					member.id,
					'members',
					'active',
					member.active
				);

				usersPermittedDiff = activeMembers - usersPermitted;

				if (usersPermittedDiff > 0) {
					utilities.showMessage(
						'Insufficient user licenses. Setting user to inactive.'
					);
					// Loop non admin active users
					for (var i = members.length - 1; i >= 0; i--) {
						if (members[i].admin === false) {
							members[i].active = false;
							inactiveCount++;
							// Update data
							updateGroupMember(
								members,
								members[i].id,
								'members',
								'active',
								members[i].active
							);

							// Exit if we have made enough users inactive
							if (inactiveCount === usersPermittedDiff) {
								break;
							}
						}
					}

					// If there are still more to set as inactive loop last admin as needed
					if (usersPermittedDiff > inactiveCount) {
						for (var i = members.length - 1; i >= 0; i--) {
							if (
								members[i].active !== false &&
								config.userID !== members[i].userID
							) {
								inactiveCount++;

								//  Update data
								updateGroupMember(
									members,
									members[i].id,
									'members',
									'active',
									members[i].active
								);

								// Exit if we have made enough users inactive
								if (inactiveCount >= usersPermittedDiff) {
									break;
								}
							}
						}
					}
				}

				// Broadcast that the active state of a member has been updated
				$scope.settings.activeMemberCount =
					manageConfig.getActiveMemberCount(members);
				$rootScope.$broadcast('memberActiveUpdate', members);
			}
		};

		$scope.gotoChangePassword = function () {
			$location.path('change-password');
		};

		$scope.toggleConfirmDelete = function (id) {
			$scope.confirmDelete = id;
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		$scope.isSalesforce = fbk.isSalesforce();
		$scope.sfOrgID =
			fbk.settings?.sr?.context?.organization?.organizationId;

		$scope.logout = function () {
			daybackIO.signOut();
		};

		/** @type {([e: MouseEvent], type: string, [category: string]) => void} */
		function selectAdminSetting(e, type, category) {
			clearSelection();
			$scope.settings.selectedAdminSetting = type;
			if (e) {
				setRoute(category ?? 'setting', type, null, null);
			}
		}

		$scope.verifyGroupMember = function (member, group) {
			if (!member.account || !member.firstName || !member.lastName) {
				$scope.settings.memberMessage = {
					type: 'error',
				};
				if (!member.account) {
					$scope.settings.memberMessage.message =
						'Account email address is required';
				} else if (!member.firstName) {
					$scope.settings.memberMessage.message =
						'First name is required';
				} else if (!member.lastName) {
					$scope.settings.memberMessage.message =
						'Last name is required';
				}
				return;
			}

			$scope.verifyingMember = true;
			// Check that there is a valid group name
			if (!primaryGroupName) {
				const message =
					'You must specify a goup name before inviting other members to your group. Please specify a group name and save to continue.';
				accountSettings.groupSettingsPopover(checkMember, message);
			} else {
				memberConflictCheck();
			}

			function checkMember(groupName) {
				if (!groupName) {
					$scope.$evalAsync(function () {
						$scope.verifyingMember = false;
					});
					return;
				}
				memberConflictCheck();
			}

			function memberConflictCheck() {
				manageConfig.memberConflictCheck(
					member,
					group,
					$scope.settings.groupMembers,
					memberCheckCallback
				);
			}

			function memberCheckCallback(result) {
				var messageType;
				var message;
				if (result && result.success) {
					// Currently don't need to show a success message becuase we show that the member was activated instead
					// messageType = 'success';
					// message = "Group member succesfully activated";
					// Broadcast that the active state of a member has been updated
					$scope.settings.activeMemberCount =
						manageConfig.getActiveMemberCount(
							$scope.settings.groupMembers
						);
					$rootScope.$broadcast(
						'memberActiveUpdate',
						$scope.settings.groupMembers
					);
				} else {
					messageType = result.hasOwnProperty('messageType')
						? result.messageType
						: 'error';
					message = result.hasOwnProperty('message')
						? result.message
						: 'This user already has a DayBack account with this group, please invite them using a different email address.';
				}
				$scope.$evalAsync(function () {
					$scope.verifyingMember = false;
					$scope.settings.memberMessage = {
						type: messageType,
						message: message,
					};
				});
			}
		};

		function getResources() {
			var config = seedcodeCalendar.get('config');
			var resources = seedcodeCalendar.get('resources');
			var result = [];
			for (var i = 0; i < resources.length; i++) {
				if (
					!resources[i].isFolder &&
					resources[i].name !== config.noFilterLabel
				) {
					result.push(resources[i]);
				}
			}
			return result;
		}

		function selectAccountType(e, type) {
			clearSelection();
			$scope.settings.selectedAccountType = type;
			if (e) {
				setRoute('account', type, null, null, null);
			}
		}

		function clearSelection() {
			$scope.settings.selectedAdminSetting = null;
			$scope.settings.sourceTemplate = null;
			$scope.settings.sourceType = null;
			$scope.settings.selectedAccountSetting = null;
			$scope.settings.viewedSources = null;
			$scope.settings.source = null;
			$scope.settings.selectedSource = null;
			$scope.settings.selectedAccountType = null;
		}

		function authFunction(
			sourceTemplate,
			source,
			statusOnly,
			forceConsent,
			callback
		) {
			var sources;
			var apiConnection = sourceTemplate.getApiConnection
				? sourceTemplate.getApiConnection()
				: null;

			if (apiConnection && apiConnection.authIsRedirect) {
				$scope.settings.authChecked = true;
			} else {
				$scope.settings.authChecked = false;
			}

			if (sourceTemplate.authFunction) {
				// if no source is passed use the first source available for that type
				if (!source) {
					sources = seedcodeCalendar.get('sources');
					for (var i = 0; i < sources.length; i++) {
						if (sources[i].sourceTypeID === sourceTemplate.id) {
							// if these are local schedules then we look specifically for the parent
							if (sourceTemplate.localSchedules) {
								if (sources[i].localParent) {
									source = sources[i];
									break;
								}
							}
							// otherwise assume first is the parent
							else {
								source = sources[i];
								break;
							}
						}
					}
				}

				sourceTemplate.authFunction(
					authCallback,
					source,
					statusOnly,
					forceConsent,
					sourceTemplate.localSchedules
				);
			}

			function authCallback(result) {
				//If Office 365, then this will be our refresh token
				$scope.$evalAsync(function () {
					$scope.settings.isAuthed = result;
					$scope.settings.authChecked = true;
					if (result) {
						//We currently run this to update data when authenticating but we also run it when selecting schedules. So we need to only run it once not twice
						updateSelectedSourceData(sourceTemplate, callback);
					}
				});
			}
			return sourceTemplate.authFunction;
		}

		function toCalendar() {
			preventLocationChangeTrigger = true;
			$location.search({});
			$location.path('/');
		}

		function setRoute(category, type, item, section) {
			if (fromLocationChange) {
				return;
			}

			var route = '/settings/';

			if (section) {
				var pathParts = $location.path().split('/');

				if (pathParts && pathParts.length > 1) {
					category = pathParts[2];
					type = pathParts[3];
					item = pathParts[4];
				}
			}

			preventLocationChangeTrigger = true;

			if (category) {
				route +=
					category.toString().replace('/', slashReplacementString) +
					'/';
			}

			if (type) {
				route +=
					type.toString().replace('/', slashReplacementString) + '/';
			}

			if (item) {
				route +=
					item.toString().replace('/', slashReplacementString) + '/';
			}

			if (section) {
				route +=
					section.toString().replace('/', slashReplacementString) +
					'/';
			}

			category = $scope.settings.category;

			$ngSilentLocation.silent(route);

			// if (category) {
			// 	setLocationSearch('category', category);
			// }

			// if (type) {
			// 	setLocationSearch('type', type);
			// } else if (item) {
			// 	setLocationSearch('type', null);
			// }

			// if (item) {
			// 	setLocationSearch('item', item);
			// } else {
			// 	setLocationSearch('item', null);
			// }

			function setLocationSearch(name, value) {
				preventLocationChangeTrigger = true;
				$location.search(name, value);
			}
		}

		function selectCategory(e, category) {
			//Check to make sure that this wasn't triggered from clicking on the settings child element as event bubbling will kick this.
			if (
				e &&
				$(e.target).parent()[0] ===
					$(e.currentTarget).find('.switch')[0]
			) {
				return;
			}

			$scope.settings.category = category;

			//For mobile and switching panels
			changeSidebarPanel('setting', true);
		}

		function selectSourceType(e, sourceTypeID, sourceID) {
			// e.preventDefault();
			// e.stopPropagation();
			//Check to make sure that this wasn't triggered from clicking on the settings child element as event bubbling will kick this.
			if (
				e &&
				$(e.target).parent()[0] ===
					$(e.currentTarget).find('.switch')[0]
			) {
				return;
			}
			clearSelection();

			var sourceTemplate = manageEventSources.getTemplate(sourceTypeID);

			$scope.settings.sourceTemplate = sourceTemplate;

			$scope.settings.sourceType = sourceTypeID;
			$scope.settings.authChecked = false;
			$scope.settings.isAuthed = false;
			$scope.settings.viewedSources = null;
			$scope.settings.sourceTypeInstructionsURL =
				sourceTemplate.hasInstructions
					? 'app/settings/source/instructions/template-' +
						sourceTypeID +
						'.html'
					: 'app/settings/source/instructions/template-' +
						'default' +
						'.html';
			var sources = manageConfig.transformSources(sourceTemplate.id);

			var primarySource = getPrimarySource(sources, sourceTemplate);

			$scope.settings.hasSource = !!primarySource;

			if (e) {
				setRoute('source', sourceTypeID, sourceID, null);
			}

			if (sourceTemplate.seperateSchedules) {
				//Check if we are authed into source
				if (authFunction) {
					if (primarySource) {
						authFunction(
							sourceTemplate,
							null,
							true,
							false,
							sourcesFetched
						);
					} else {
						$scope.settings.authChecked = true;
						sourcesFetched(sources);
					}
				}
			} else {
				updateSelectedSourceData(sourceTemplate, sourcesFetched);
			}

			function sourcesFetched(sources) {
				if (!sourceID) {
					return;
				}
				for (var i = 0; i < sources.length; i++) {
					if (sources[i].id === sourceID) {
						selectSource(sources[i]);
						break;
					}
				}
			}
		}

		function getPrimarySource(sources, sourceTemplate) {
			var sourceTypeID = sourceTemplate.id;
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTypeID) {
					if (sourceTemplate.localSchedules) {
						if (sources[i].localParent) {
							return sources[i];
						}
					} else {
						return sources[i];
					}
				}
			}
			return false;
		}

		function updateSelectedSourceData(sourceTemplate, callback) {
			var sources;
			$scope.settings.viewedSources = [];

			if (sourceTemplate.seperateSchedules) {
				var functionResult = sourceTemplate.schedules(
					function (result) {
						$scope.$evalAsync(function () {
							if (
								sourceTemplate.id === $scope.settings.sourceType
							) {
								sources = manageConfig.transformSources(
									sourceTemplate.id,
									result,
									sourceTemplate.localSchedules
								);

								$scope.settings.viewedSources = sources;
								$scope.settings.hasSource = !!getPrimarySource(
									sources,
									sourceTemplate
								);
								if (callback) {
									callback(sources);
								}
							}
						});
					}
				);
			} else {
				sources = manageConfig.transformSources(sourceTemplate.id);
				$scope.settings.viewedSources = sources;
				$scope.settings.hasSource = !!getPrimarySource(
					sources,
					sourceTemplate
				);
				if (callback) {
					callback(sources);
				}
			}
		}

		function addSource(sourceType) {
			var hasSource = $scope.settings.hasSource;
			var sourceTemplate = manageEventSources.getTemplate(sourceType);

			var source = manageConfig.addSource(
				sourceType,
				!hasSource && sourceTemplate.localSchedules
			);

			//Run onboarding function for source if there is one
			if (sourceTemplate.onboarding) {
				// Pass methods for working with returned data
				sourceTemplate.onboarding({defaultSources: addDefaultSources});
			}

			$scope.settings.viewedSources =
				manageConfig.transformSources(sourceType);

			if (!hasSource) {
				$scope.settings.hasSource = true;
				$scope.settings.isAuthed = false;
			}

			if (sourceTemplate.seperateSchedules && !hasSource) {
				//Check if we are authed into source
				if (authFunction) {
					$scope.settings.authChecked = false;
					authFunction(sourceTemplate, source, false, true);
				}
				// updateSelectedSourceData(sourceTemplate);
			} else {
				selectSource(source, true);
			}

			// Will take an object of sources and add them as new sources
			// Useful for onboarding purposes when sources should be added on first auth
			function addDefaultSources(onboardingSources) {
				if (!hasSource && onboardingSources) {
					// Create new onboarding sources
					for (var property in onboardingSources) {
						manageConfig.addSource(
							sourceType,
							false,
							onboardingSources[property]
						);
					}
				}
			}
		}

		function deleteSource(source, isParent, callback) {
			var sourceType = source.sourceTypeID;
			var sourceTemplate = manageEventSources.getTemplate(
				source.sourceTypeID
			);

			//For sources that use separate schedules we wan't to say that we aren't authed after delete
			if (
				(sourceTemplate.seperateSchedules &&
					!sourceTemplate.localSchedules) ||
				(sourceTemplate.localSchedules && isParent)
			) {
				if (sourceTemplate.deauthFunction) {
					sourceTemplate.deauthFunction(
						function () {
							$scope.settings.isAuthed = false;
							manageConfig.addSource(
								sourceType,
								sourceTemplate.localSchedules && isParent
							);
							completeDeleteSource();
						},
						false,
						sourceTemplate
					);
				}
			} else {
				completeDeleteSource();
			}

			function completeDeleteSource() {
				manageConfig.deleteSource(source);
				//Update our display
				$scope.confirmDelete = null;
				$scope.settings.selectedSource = null;
				updateSelectedSourceData(sourceTemplate, callback);
			}

			// Set route
			setRoute('source', source.sourceTypeID, null, null);

			// $scope.settings.viewedSources = manageConfig.transformSources(sourceType);
		}

		function selectSource(source, newSource) {
			var sourceTemplate = manageEventSources.getTemplate(
				source.sourceTypeID
			);

			var entity = selectedEntity;
			var action = selectedAction;
			var section = selectedSection;

			$scope.settings.action = action;
			$scope.settings.entity = entity;
			$scope.settings.section = section;

			selectedEntity = null;
			selectedAction = null;
			selectedSection = null;

			if (authFunction && !sourceTemplate.seperateSchedules) {
				$scope.settings.authChecked = false;
				authFunction(sourceTemplate, source, true);
			} else {
				$scope.settings.isAuthed = sourceTemplate.status.authed;
			}

			$scope.settings.newSource = newSource;

			$scope.settings.selectedSource = source;
			$scope.settings.source = manageConfig.transformSource(
				source,
				'settings'
			);

			generateFieldMap(source);

			$scope.settings.contactData = manageConfig.transformSource(
				source,
				'contactData'
			);
			$scope.settings.projectData = manageConfig.transformSource(
				source,
				'projectData'
			);

			//set scope variables for optional related Ids
			if (!$scope.settings.selectedSource.relatedValueMap) {
				$scope.settings.selectedSource.relatedValueMap = {};
			}
			//using a second scope variable to display the related resource so we can
			//change it on a timeout which flows a little better.
			$scope.settings.relatedValues = {};
			if ($scope.settings.selectedSource.relatedValueMap.resourceID) {
				$scope.settings.relatedValues.resourceID = true;
			} else {
				$scope.settings.relatedValues.resourceID = false;
			}

			$scope.settings.customFieldFormats = getCustomFieldFormats();

			$scope.settings.eventTypes = getEventTypes();

			// Update route
			setRoute('source', source.sourceTypeID, source.id, null);

			// Update tab panel for mobile
			changeSidebarPanel('source', true);

			updateGroupAuthStatus(source);
			getContactObjects(source);
			getProjectObjects(source);
			getResourceObjects(source);
			getCustomFields(source);
			getEventActions(source);
			getCustomActions(source);
			// $scope.settings.customActions = manageConfig.transformSource(source, 'customActions');
			$scope.settings.showServerTestResults = false;
			$scope.settings.showMappingTestResults = false;
			$scope.settings.showContactTestResults = false;
			$scope.settings.showProjectTestResults = false;
			$scope.settings.showLayoutTestResults = false;
			$scope.settings.tabName = 'Source Settings';

			if (
				$scope.settings.sourceType === 4 ||
				$scope.settings.sourceType === 10
			) {
				//if we have a SF object populated, then let's run the test to populate the select options.
				//we can leave the results hidden
				testSalesForceObject(processRecordTypes);
			} else if ($scope.settings.sourceType === 8) {
				fileMakerLayoutTable(source.layoutName, processResult);
				return;
			}

			//check url params here too as all calendars should be loaded and selected
			//and action may need to be pointed here

			if (action === 'create' && entity === 'customField') {
				//new custom field
				settingsTab('Custom Fields');
				addCustomField(
					$scope.settings.customFields,
					$scope.settings.selectedSource,
					true
				);
			} else if (action === 'create' && entity === 'customButton') {
				settingsTab('Actions & Buttons');
				addCustomAction(
					$scope.settings.customActions,
					$scope.settings.selectedSource,
					true
				);
			} else if (section) {
				settingsTab(section);
			}

			function processResult(data) {
				//backward compatability
				if (data.result.indexOf('fieldData') !== -1) {
					setFileMakerTable(data);
					$scope.settings.selectedSource.newFMJS = true;
				} else {
					loadFileMakerJSConfig(processFileMakerJSConfig);
					$scope.settings.selectedSource.newFMJS = false;
				}
			}
		}

		function generateFieldMap(source) {
			$scope.settings.fieldMap = manageConfig.transformSource(
				source,
				'fieldMap'
			);

			if (
				source.isMapOnly &&
				source.queryOnGeocode &&
				source.unusedMap?.geocode
			) {
				// delete source.unusedMap.geocode;
				toggleField(source, null, 'geocode', null, 'unusedMap');
			} else if (
				(!source.queryOnGeocode || !source.isMapOnly) &&
				source.unusedMap?.start
			) {
				// delete source.unusedMap.start;
				toggleField(source, null, 'start', null, 'unusedMap');
			}

			$scope.settings.unusedMap = manageConfig.transformSource(
				source,
				'unusedMap'
			);
		}

		function updateGroupAuthStatus(source) {
			var sourceTemplate = source.sourceTemplate;
			var config = seedcodeCalendar.get('config');

			if ($scope.settings.selectedSource.groupAuthDetail) {
				$scope.settings.selectedSource.groupAuthDetail = null;
			}

			if (source.isParent && source.groupAuthAvailable) {
				// Check for group auth success
				$scope.settings.loadingGroupAuth = true;
				sourceTemplate.getSecondaryAuth(
					config.userID,
					config.groupID,
					source.id,
					function (result) {
						$scope.$evalAsync(function () {
							$scope.settings.loadingGroupAuth = false;
							$scope.settings.selectedSource.groupAuthDetail = {
								name: result.name,
								email: result.accountName,
							};
						});
					}
				);
			}
		}

		function settingsTab(tabName) {
			if (
				tabName !== 'Source Settings' &&
				tabName !== 'Related Records' &&
				tabName !== 'Actions & Buttons' &&
				($scope.settings.sourceType === 4 ||
					$scope.settings.sourceType === 10)
			) {
				if ($scope.mappingTest && $scope.mappingTest.mappingTested) {
					$scope.mappingTest.mappingTested = false;
					$scope.settings.showMappingTestResults = false;
				}
				testSalesForceObject(function (result) {
					if (!result) {
						var message = $scope.settings.selectedSource.objectName
							? $scope.settings.selectedSource.objectName +
								' is not a valid Salesforce Object name.'
							: 'Please specify a valid Salesforce Object name.';
						utilities.showMessage(message, 0, 5000, 'error');
					}
				});
				navigate();
			} else if (
				tabName === 'Related Records' &&
				$scope.settings.sourceType === 8
			) {
				if ($scope.settings.contactData) {
					var theSetting;
					for (
						var i = 0;
						i < $scope.settings.contactData.length;
						i++
					) {
						if (
							$scope.settings.contactData[i].name ===
							'searchLayout'
						) {
							theSetting = $scope.settings.contactData[i];
							break;
						}
					}
					// var data = {};
					// data.status = 200;
					// data.result = theSetting.data;
					//updateContactData(data);
					fileMakerLayoutTable(theSetting.data, updateContactData);
				}
				if ($scope.settings.projectData) {
					var theSetting;
					for (
						var i = 0;
						i < $scope.settings.projectData.length;
						i++
					) {
						if (
							$scope.settings.projectData[i].name ===
							'searchLayout'
						) {
							theSetting = $scope.settings.projectData[i];
							break;
						}
					}
					// var data = {};
					// data.status = 200;
					// data.result = theSetting.data;
					//updateProjectData(data);
					fileMakerLayoutTable(theSetting.data, updateProjectData);
				}
				//update field drop downs.
				navigate(true);
			} else {
				navigate(true);
			}

			// Update route
			setRoute(null, null, null, tabName);

			function navigate(result) {
				$scope.settings.tabName = tabName;
			}
		}

		function toggleSourceTypeEnabled(id, enabled) {
			var sourceTemplates = $scope.settings.sourceTypes;
			var savedSourceTypes = seedcodeCalendar.get('config').sourceTypes;

			var sources = seedcodeCalendar.get('sources');

			var primarySource;

			//Get our specific source type
			for (var i = 0; i < sourceTemplates.length; i++) {
				if (sourceTemplates[i].id === id) {
					primarySource = getPrimarySource(
						sources,
						sourceTemplates[i]
					);
					// Add source record if none exists for source types that require it
					if (
						!primarySource &&
						sourceTemplates[i].seperateSchedules
					) {
						manageConfig.addSource(
							id,
							sourceTemplates[i].localSchedules
						);
					}

					if (savedSourceTypes[sourceTemplates.propertyName]) {
						manageSettings.setSourceTypeSettings(
							sourceTemplates[i].propertyName,
							'disabled',
							!enabled,
							null
						);
					} else {
						manageSettings.setSourceTypeSettings(
							'',
							sourceTemplates[i].propertyName,
							{id: sourceTemplates[i].id, disabled: !enabled},
							null
						);
					}
					sourceTemplates[i].status.disabled = !enabled;
					break;
				}
			}
		}

		function sourceSettingExit(property) {
			if ($scope.isSalesforce && property === 'objectName') {
				testSalesForceObject(processRecordTypes);
			}
		}

		function loadFileMakerJSConfig(callback) {
			var payload = {};
			payload.script = 'Get File Config - DayBack';
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = callback;
			payload.callback = callbackId;
			payload.dbk = true;
			utilities.fileMakerCall(payload);
		}

		function processFileMakerJSConfig(data) {
			var i;
			$scope.$evalAsync(function () {
				if (data.status === 200) {
					var fieldData = data.payload.fieldData;
					var layoutData = data.payload.layoutData;
					var relationshipData = data.payload.relationshipData;

					$scope.settings.fileMakerJSLayouts =
						fileMakerSelectList(layoutData);
					$scope.settings.fileMakerJSFields = {};
					var tableHash = {};
					var tables = [];

					if (fieldData) {
						for (i = 0; i < fieldData.length; i++) {
							var table = fieldData[i][0];
							var field = fieldData[i][1];
							var type = fieldData[i][2];
							var fieldClass = fieldData[i][3];
							//tables
							if (!tableHash[table]) {
								tables.push(table);
								tableHash[table] = true;
							}
							$scope.settings.fileMakerJSTables =
								fileMakerSelectList(tables);
							//fields, create table property as needed
							if (!$scope.settings.fileMakerJSFields[table]) {
								$scope.settings.fileMakerJSFields[table] = {};
							}
							//all fields
							if (!$scope.settings.fileMakerJSFields[table].all) {
								$scope.settings.fileMakerJSFields[table].all = [
									newLine(),
								];
							}
							//dates
							$scope.settings.fileMakerJSFields[table].all.push(
								newLine(field)
							);
							if (type === 'date') {
								if (
									!$scope.settings.fileMakerJSFields[table]
										.dates
								) {
									$scope.settings.fileMakerJSFields[
										table
									].dates = [newLine()];
								}
								$scope.settings.fileMakerJSFields[
									table
								].dates.push(newLine(field));
							}
							//times
							if (type === 'time') {
								if (
									!$scope.settings.fileMakerJSFields[table]
										.times
								) {
									$scope.settings.fileMakerJSFields[
										table
									].times = [newLine()];
								}
								$scope.settings.fileMakerJSFields[
									table
								].times.push(newLine(field));
							}
							//timestamp
							if (type === 'timestamp') {
								if (
									!$scope.settings.fileMakerJSFields[table]
										.timestamps
								) {
									$scope.settings.fileMakerJSFields[
										table
									].timestamps = [newLine()];
								}
								$scope.settings.fileMakerJSFields[
									table
								].timestamps.push(newLine(field));
							}
							//text (status, resource)
							if (
								type === 'varchar' &&
								fieldClass !== 'Summary'
							) {
								if (
									!$scope.settings.fileMakerJSFields[table]
										.text
								) {
									$scope.settings.fileMakerJSFields[
										table
									].text = [newLine()];
								}
								$scope.settings.fileMakerJSFields[
									table
								].text.push(newLine(field));
							}
							//numbers
							if (
								type === 'decimal' &&
								fieldClass !== 'Summary'
							) {
								if (
									!$scope.settings.fileMakerJSFields[table]
										.numbers
								) {
									$scope.settings.fileMakerJSFields[
										table
									].numbers = [newLine()];
								}
								$scope.settings.fileMakerJSFields[
									table
								].numbers.push(newLine(field));
							}
							if (
								(type === 'decimal' &&
									fieldClass !== 'Summary') ||
								(type === 'varchar' && fieldClass !== 'Summary')
							) {
								if (
									!$scope.settings.fileMakerJSFields[table]
										.ids
								) {
									$scope.settings.fileMakerJSFields[
										table
									].ids = [newLine()];
								}
								$scope.settings.fileMakerJSFields[
									table
								].ids.push(newLine(field));
							}
							if ($scope.settings.fileMakerJSFields[table].ids) {
								$scope.settings.fileMakerJSFields[
									table
								].ids.sort(compare);
							}
							if (
								$scope.settings.fileMakerJSFields[table].numbers
							) {
								$scope.settings.fileMakerJSFields[
									table
								].numbers.sort(compare);
							}
							if ($scope.settings.fileMakerJSFields[table].text) {
								$scope.settings.fileMakerJSFields[
									table
								].text.sort(compare);
							}
							if (
								$scope.settings.fileMakerJSFields[table]
									.timestamps
							) {
								$scope.settings.fileMakerJSFields[
									table
								].timestamps.sort(compare);
							}
							if (
								$scope.settings.fileMakerJSFields[table].times
							) {
								$scope.settings.fileMakerJSFields[
									table
								].times.sort(compare);
							}
							if (
								$scope.settings.fileMakerJSFields[table].dates
							) {
								$scope.settings.fileMakerJSFields[
									table
								].dates.sort(compare);
							}
							if ($scope.settings.fileMakerJSFields[table].all) {
								$scope.settings.fileMakerJSFields[
									table
								].all.sort(compare);
							}
						}
					}

					if (relationshipData) {
						//create key and name picklists from relationship data by table
						for (i = 0; i < tables.length; i++) {
							var foreignKeys = [newLine()];
							var names = [newLine()];
							var tableData = relationshipData[tables[i]];
							var relatedNames = {};
							relatedNames[tables[i]] = {};
							var primaryKeys = {};
							primaryKeys[tables[i]] = {};
							var collapsedTable = false;
							var tableName;
							for (var thisTable in tableData) {
								//if the Table occurrence has a dots in the name, then those willbe new nodes in the object
								//calculate the actual name and compress the object
								if (!tableData[thisTable].fk) {
									for (var extraObject in tableData[
										thisTable
									]) {
										names = [newLine()];
										var keysToCollapse = findKeysToCollapse(
											tableData[thisTable][extraObject],
											extraObject,
											thisTable
										);
										collapsedTable = collapseKeys(
											tableData,
											keysToCollapse
										);
										tableName = collapsedTable
											? keysToCollapse.join('.')
											: thisTable;
										if (collapsedTable.fk) {
											for (
												var k = 0;
												k < collapsedTable.fk.length;
												k++
											) {
												var key = collapsedTable.fk[k];
												var keyField =
													key.split('::')[1];
												foreignKeys.push(
													newLine(keyField)
												);
											}
											foreignKeys.sort(compare);
										}
										if (
											collapsedTable.pk &&
											collapsedTable.pk[0]
										) {
											primaryKeys[tables[i]][keyField] =
												collapsedTable.pk;
											[0];
										}
										if (collapsedTable.nameFields) {
											for (
												var n = 0;
												n <
												collapsedTable.nameFields
													.length;
												n++
											) {
												var thisName =
													collapsedTable.nameFields[
														n
													];
												names.push(
													newLine(
														tableName +
															'::' +
															thisName
													)
												);
											}
											relatedNames[tables[i]][keyField] =
												names.sort(compare);
										}
									}

									if (
										!$scope.settings.fileMakerJSFields[
											tableName
										]
									) {
										$scope.settings.fileMakerJSFields[
											tableName
										] = {};
									}

									if (
										!$scope.settings.fileMakerJSFields[
											tableName
										].relatedNames
									) {
										$scope.settings.fileMakerJSFields[
											tableName
										].relatedNames = relatedNames;
									}
									if (
										!$scope.settings.fileMakerJSFields[
											tableName
										].foreignKeys
									) {
										$scope.settings.fileMakerJSFields[
											tableName
										].foreignKeys = foreignKeys;
									}
									if (
										!$scope.settings.fileMakerJSFields[
											tableName
										].primaryKeys
									) {
										$scope.settings.fileMakerJSFields[
											tableName
										].primaryKeys = primaryKeys;
									}
								} else {
									names = [newLine()];
									tableName = thisTable;
									if (tableData[tableName].fk) {
										for (
											var k = 0;
											k < tableData[tableName].fk.length;
											k++
										) {
											var key =
												tableData[tableName].fk[k];
											var keyField = key.split('::')[1];
											foreignKeys.push(newLine(keyField));
										}
										foreignKeys.sort(compare);
									}
									if (tableData[tableName].nameFields) {
										for (
											var n = 0;
											n <
											tableData[tableName].nameFields
												.length;
											n++
										) {
											var thisName =
												tableData[tableName].nameFields[
													n
												];
											names.push(
												newLine(
													tableName + '::' + thisName
												)
											);
										}
										relatedNames[tables[i]][keyField] =
											names.sort(compare);
									}
									if (
										tableData[tableName].pk &&
										tableData[tableName].pk[0]
									) {
										primaryKeys[tables[i]][keyField] =
											tableData[tableName].pk;
										[0];
									}
								}
								if (
									!$scope.settings.fileMakerJSFields[
										tables[i]
									].relatedNames
								) {
									$scope.settings.fileMakerJSFields[
										tables[i]
									].relatedNames = relatedNames;
								}
								if (
									!$scope.settings.fileMakerJSFields[
										tables[i]
									].foreignKeys
								) {
									$scope.settings.fileMakerJSFields[
										tables[i]
									].foreignKeys = foreignKeys;
								}
								if (
									!$scope.settings.fileMakerJSFields[
										tables[i]
									].primaryKeys
								) {
									$scope.settings.fileMakerJSFields[
										tables[i]
									].primaryKeys = primaryKeys;
								}
							}
						}

						function findKeysToCollapse(object, name, parentName) {
							var result = [parentName, name];
							var thisObject = object;
							var childKey;
							while (!thisObject.fk) {
								childKey = Object.keys(thisObject)[0];
								result.push(childKey);
								thisObject = thisObject[childKey];
							}
							return result;
						}

						function collapseKeys(tableData, keys) {
							var newKey = keys.join('.');
							tableData[newKey] = {};
							if (keys[5]) {
								return false;
							}
							if (keys[4]) {
								tableData[newKey].fk = tableData[keys[4]].fk;
								tableData[newKey].pk = tableData[keys[4]].pk;
								tableData[newKey].nameFields =
									tableData[keys[4]].nameFields;
							} else if (keys[3]) {
								tableData[newKey].fk =
									tableData[keys[0]][keys[1]][keys[2]][
										keys[3]
									].fk;
								tableData[newKey].pk =
									tableData[keys[0]][keys[1]][keys[2]][
										keys[3]
									].pk;
								tableData[newKey].nameFields =
									tableData[keys[0]][keys[1]][keys[2]][
										keys[3]
									].nameFields;
							} else if (keys[2]) {
								tableData[newKey].fk =
									tableData[keys[0]][keys[1]][keys[2]].fk;
								tableData[newKey].pk =
									tableData[keys[0]][keys[1]][keys[2]].pk;
								tableData[newKey].nameFields =
									tableData[keys[0]][keys[1]][
										keys[2]
									].nameFields;
							} else if (keys[1]) {
								tableData[newKey].fk =
									tableData[keys[0]][keys[1]].fk;
								tableData[newKey].pk =
									tableData[keys[0]][keys[1]].pk;
								tableData[newKey].nameFields =
									tableData[keys[0]][keys[1]].nameFields;
							} else if (keys[0]) {
								tableData[newKey].fk = tableData[keys[0]].fk;
								tableData[newKey].pk = tableData[keys[0]].pk;
								tableData[newKey].nameFields =
									tableData[keys[0]].nameFields;
							}
							return tableData[newKey];
						}
					}
				}

				//update layout drop-down
				var validLayout;
				for (i = 0; i < $scope.settings.source.length; i++) {
					if (
						$scope.settings.source[i].name === 'layoutName' ||
						$scope.settings.source[i].name === 'editLayoutName'
					) {
						var layoutName = $scope.settings.source[i].data;
						var validLayouts = $scope.settings.fileMakerJSLayouts;
						validLayout = false;
						for (var l = 0; l < validLayouts.length; l++) {
							if (
								validLayouts[l].id &&
								validLayouts[l].id === layoutName
							) {
								validLayout = true;
							}
						}
						$scope.settings.source[i].options = validLayouts;
						if (!validLayout && layoutName) {
							$scope.settings.source[i].options.push({
								id: layoutName,
								label: layoutName,
							});
						}
					}

					if ($scope.settings.source[i].name === 'tableName') {
						var tableName = $scope.settings.source[i].data;
						//$scope.settings.source[i].options = [];
						if (tableName) {
							//if(!$scope.settings.source[i].options) {
							$scope.settings.source[i].options = [];
							$scope.settings.source[i].options.push({
								id: '',
								label: '',
							});
							//}
							$scope.settings.source[i].options.push({
								id: tableName,
								label: tableName,
							});
						}
					}
				}

				if ($scope.settings.action) {
					if (
						$scope.settings.action === 'create' &&
						$scope.settings.entity === 'customField'
					) {
						//new custom field
						settingsTab('Custom Fields');
						addCustomField(
							$scope.settings.customFields,
							$scope.settings.selectedSource,
							true
						);
					} else if (
						$scope.settings.action === 'create' &&
						$scope.settings.entity === 'customButton'
					) {
						settingsTab('Actions & Buttons');
						addCustomAction(
							$scope.settings.customActions,
							$scope.settings.selectedSource,
							true
						);
					} else if ($scope.settings.section) {
						settingsTab($scope.settings.section);
					}
					$scope.settings.action = null;
					$scope.settings.entity = null;
					$scope.settings.selection = null;
				}

				loadCustomFieldPicklistsFM();
			});

			function fileMakerSelectList(data) {
				var result = [newLine()];
				for (var i = 0; i < data.length; i++) {
					result.push(newLine(data[i]));
				}
				return result;
			}
			function newLine(field) {
				if (!field) {
					field = '';
				}
				return {id: field, label: field};
			}
			function compare(a, b) {
				if (a.label < b.label) {
					return -1;
				} else if (a.label > b.label) {
					return 1;
				} else {
					return 0;
				}
			}
		}

		function loadCustomFieldPicklistsFM() {
			//Set custom field select list
			var customFieldFormats = getCustomFieldFormats();
			var customFieldSelectList = {};
			for (var i = 0; i < customFieldFormats.length; i++) {
				customFieldSelectList[customFieldFormats[i].id] =
					customFieldPickerFM(customFieldFormats[i].id);
			}
			$scope.customFieldSelectList = customFieldSelectList;
		}

		function customFieldPickerFM(format) {
			var fields;
			try {
				fields =
					$scope.settings.fileMakerJSFields[
						$scope.settings.selectedSource.tableName
					];
			} catch (error) {
				return [];
			}

			if (!fields) {
				return [];
			}

			var dataTypeList;

			if (format === 'edit') {
				dataTypeList = utilities.mergeArrays(
					fields.text,
					fields.numbers,
					'id'
				);
			} else if (format === 'date') {
				dataTypeList = fields.dates;
			} else if (format === 'timestamp') {
				dataTypeList = fields.timestamps;
			} else if (
				format === 'number' ||
				format === 'currency' ||
				format === 'percent'
			) {
				dataTypeList = fields.numbers;
			} else if (format === 'checkbox') {
				dataTypeList = fields.numbers;
			} else if (
				format === 'select' ||
				format === 'drop' ||
				format === 'radio'
			) {
				dataTypeList = fields.text;
			} else if (format === 'url') {
				dataTypeList = fields.text;
			}

			return dataTypeList;
		}

		function fmJSOptionsByField(property, value, table) {
			var result = false;
			if (!$scope.settings.fileMakerJSFields[table]) {
				return [
					{
						id: value || '',
						label: value || '',
						$$hashKey: 'object:1353',
					},
				];
			}

			var fields = $scope.settings.fileMakerJSFields[table];
			//clone this and remove the empty, so we can append to our project and contact names
			//if they're using a look-up/auto-enter instead of the relared field.
			var localNames = JSON.parse(JSON.stringify(fields.text));
			localNames.shift();
			var layouts = $scope.settings.fileMakerJSLayouts;
			if (property === 'start' || property === 'end') {
				result = false;
				for (var i = 0; i < fields.numbers.length; i++) {
					if (value === fields.numbers[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.numbers.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.numbers;
				}
			}
			if (property === 'dateStart' || property === 'dateEnd') {
				result = false;
				for (var i = 0; i < fields.dates.length; i++) {
					if (value === fields.dates[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.dates.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.dates;
				}
			}
			if (property === 'timeStart' || property === 'timeEnd') {
				result = false;
				for (var i = 0; i < fields.times.length; i++) {
					if (value === fields.times[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.times.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.times;
				}
			}
			if (property === 'eventID') {
				result = false;
				for (var i = 0; i < fields.ids.length; i++) {
					if (value === fields.ids[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.ids.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.ids;
				}
			}
			if (property === 'titleEdit') {
				result = false;
				for (var i = 0; i < fields.text.length; i++) {
					if (value === fields.text[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.text.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.text;
				}
			}
			if (property === 'title') {
				result = false;
				for (var i = 0; i < fields.text.length; i++) {
					if (value === fields.text[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.text.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.text;
				}
			}
			if (property === 'description') {
				result = false;
				for (var i = 0; i < fields.text.length; i++) {
					if (value === fields.text[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.text.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.text;
				}
			}
			if (property === 'location' || property === 'geocode') {
				return fields.text;
			}
			if (property === 'resource') {
				result = false;
				for (var i = 0; i < fields.text.length; i++) {
					if (value === fields.text[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.text.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.text;
				}
			}
			if (property === 'status') {
				result = false;
				for (var i = 0; i < fields.text.length; i++) {
					if (value === fields.text[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.text.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.text;
				}
			}
			if (property === 'tags' || property === 'recurringEventID') {
				result = false;
				for (var i = 0; i < fields.text.length; i++) {
					if (value === fields.text[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.text.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.text;
				}
			}
			if (property === 'unscheduled') {
				result = false;
				for (var i = 0; i < fields.numbers.length; i++) {
					if (value === fields.numbers[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.numbers.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.numbers;
				}
			}

			if (
				property === 'featuredImage' ||
				property === 'featuredImageClass' ||
				property === 'featuredImageThumbnail' ||
				property === 'featuredImageThumbnailClass'
			) {
				result = false;
				for (var i = 0; i < fields.text.length; i++) {
					if (value === fields.text[i].id) {
						result = true;
					}
				}
				if (!result) {
					return fields.text.concat({
						id: value || '',
						label: value || '',
						$$hashKey: 'object:9999',
					});
				} else {
					return fields.text;
				}
			}
			if (property === 'contactID') {
				if (value) {
					updateFMJSRelated(property, value, table);
				}
				return fields.foreignKeys;
			}
			if (property === 'projectID') {
				if (value) {
					updateFMJSRelated(property, value, table);
				}
				return fields.foreignKeys;
			}
			if (property === 'contactName') {
				if (fields.contactNames) {
					return fields.contactNames.concat(localNames);
				} else {
					return localNames;
				}
			}
			if (property === 'projectName') {
				if (fields.projectNames) {
					return fields.projectNames.concat(localNames);
				} else {
					return localNames;
				}
			}
			if (property === 'searchField' || property === 'displayField') {
				if (value === 'contact') {
					updateFMJSRelated(
						'contactID',
						$scope.settings.selectedSource.fieldMap.contactID,
						table
					);
					return fields.contactSearchNames;
				} else if (value === 'project') {
					updateFMJSRelated(
						'projectID',
						$scope.settings.selectedSource.fieldMap.projectID,
						table
					);
					return fields.projectSearchNames;
				}
			}
			if (property === 'searchLayout') {
				return layouts;
			}
			if (property === 'navigationLayout') {
				return layouts;
			}
		}

		function updateFMJSRelated(property, value, table) {
			var result = [];

			if (value) {
				// Make sure there are related fields
				if (
					$scope.settings.fileMakerJSFields[table].relatedNames &&
					$scope.settings.fileMakerJSFields[table].relatedNames[table]
				) {
					result =
						$scope.settings.fileMakerJSFields[table].relatedNames[
							table
						][value] || [];
				} else {
					result = [];
				}
			}
			if (property === 'contactID') {
				$scope.settings.fileMakerJSFields[table].contactNames = result;
			} else if (property === 'projectID') {
				$scope.settings.fileMakerJSFields[table].projectNames = result;
			}
		}

		function processRecordTypes() {
			for (var i = 0; i < $scope.settings.source.length; i++) {
				if ($scope.settings.source[i].name === 'recordType') {
					$scope.settings.source[i].options = recordTypeValueList(
						$scope.recordTypes
					);
				}
			}
		}

		function recordTypeValueList(recordTypeObj) {
			var result = [
				{
					id: '',
					label: '',
				},
			];

			if (!recordTypeObj) {
				return result;
			}

			for (var i = 0; i < recordTypeObj.length; i++) {
				if (recordTypeObj && recordTypeObj[i]) {
					result.push({
						id: recordTypeObj[i].Id,
						label: recordTypeObj[i].DeveloperName,
					});
				}
			}
			return result;
		}

		$scope.defaultResourceChanged = function (source) {
			manageConfig.defaultResourceChanged(source);
		};

		function updateSource(
			source,
			id,
			property,
			value,
			type,
			viewedSources,
			forceBoolean
		) {
			$scope.settings.showServerTestResults = false;

			manageConfig.updateSource(
				source,
				id,
				property,
				value,
				type,
				viewedSources
			);

			if (property === 'allowUnscheduled') {
				if (value && source.hideMap.unscheduled) {
					delete source.hideMap.unscheduled;
				} else if (!value && !source.hideMap.unscheduled) {
					source.hideMap.unscheduled = true;
				}
			}
			if (type === 'contactData' && source.sourceTypeID === 8) {
				if ($scope.settings.showContactTestResults) {
					$scope.settings.showContactTestResults = false;
				}
				if (property === 'searchLayout') {
					fileMakerLayoutTable(value, updateContactData);
				}
			}
			if (type === 'projectData' && source.sourceTypeID === 8) {
				if ($scope.settings.showProjectTestResults) {
					$scope.settings.showProjectTestResults = false;
				}
				if (property === 'searchLayout') {
					fileMakerLayoutTable(value, updateProjectData);
				}
			}
			if (property === 'layoutName' && source.sourceTypeID === 8) {
				//clear test results and update the table name
				$scope.settings.showLayoutTestResults = false;
				fileMakerLayoutTable(value, setFileMakerTable);
			}

			//If we are changing the has times property for salesforce run the object test to refresh field pick lists and clear date / time data
			if ($scope.isSalesforce && property === 'allowAllDay') {
				updateFieldValue(
					$scope.settings.selectedSource,
					null,
					'start',
					'',
					'fieldMap'
				);
				updateFieldValue(
					$scope.settings.selectedSource,
					null,
					'end',
					'',
					'fieldMap'
				);
				updateFieldValue(
					$scope.settings.selectedSource,
					null,
					'allDay',
					'',
					'fieldMap'
				);
				testSalesForceObject();
			}

			if (forceBoolean) {
				if (source && type && id && property) {
					source[type][id][property] = !value ? false : true;
				}
			}
		}

		function updateProjectData(data) {
			var table;
			if (data.status === 200) {
				if (data.result.indexOf(':[') !== -1) {
					var result = JSON.parse(data.result);
					table = result.table;
				} else {
					table = data.result;
				}
			}
			var primaryKey = $scope.settings.selectedSource.projectPrimaryKey;
			if (primaryKey) {
				var keyOnly = primaryKey.split('::')[1];
				var newKey = table + '::' + keyOnly;
				if (
					newKey !== $scope.settings.selectedSource.projectPrimaryKey
				) {
					$scope.settings.selectedSource.projectPrimaryKey = newKey;
					updateSource(
						$scope.settings.selectedSource,
						null,
						'projectPrimaryKey',
						newKey,
						'settings'
					);
				}
			}

			var parentTableName = $scope.settings.selectedSource.tableName;
			var fields = $scope.settings.fileMakerJSFields[parentTableName];

			var fieldMap = $scope.settings.selectedSource.fieldMap;
			var key = fieldMap.projectID;
			var projectNames = fields.relatedNames[parentTableName][key];
			if (projectNames) {
				var searchNames = [];
				for (var i = 0; i < projectNames.length; i++) {
					var thisLine = projectNames[i];
					var thisId = projectNames[i].id;
					var thisLabel = projectNames[i].label;
					if (!thisId) {
						searchNames.push(projectNames[i]);
					} else {
						var newObject = {};
						newObject.id = table + '::' + thisId.split('::')[1];
						newObject.label =
							table + '::' + thisLabel.split('::')[1];
						//newObject.$$haskKey = contactNames[i].$$hashKey;
						searchNames.push(newObject);
					}
				}
			}
			$scope.$evalAsync(function () {
				if (!table) {
					fields.projectSearchNames = [];
				} else if (searchNames) {
					fields.projectSearchNames = searchNames;
				}
			});
		}

		function updateContactData(data) {
			var table;
			if (data.status === 200) {
				if (data.result.indexOf(':[') !== -1) {
					var result = JSON.parse(data.result);
					table = result.table;
				} else {
					table = data.result;
				}
			}
			var primaryKey = $scope.settings.selectedSource.contactPrimaryKey;
			if (primaryKey) {
				var keyOnly = primaryKey.split('::')[1];
				var newKey = table + '::' + keyOnly;
				if (
					newKey !== $scope.settings.selectedSource.contactPrimaryKey
				) {
					$scope.settings.selectedSource.contactPrimaryKey = newKey;
					updateSource(
						$scope.settings.selectedSource,
						null,
						'contactPrimaryKey',
						newKey,
						'settings'
					);
				}
			}
			var parentTableName = $scope.settings.selectedSource.tableName;
			var fields = $scope.settings.fileMakerJSFields[parentTableName];
			var fieldMap = $scope.settings.selectedSource.fieldMap;
			var key = fieldMap.contactID;
			var contactNames = fields.relatedNames[parentTableName][key];
			if (contactNames) {
				var searchNames = [];
				for (var i = 0; i < contactNames.length; i++) {
					var thisLine = contactNames[i];
					var thisId = contactNames[i].id;
					var thisLabel = contactNames[i].label;
					if (!thisId) {
						searchNames.push(contactNames[i]);
					} else {
						var newObject = {};
						newObject.id = table + '::' + thisId.split('::')[1];
						newObject.label =
							table + '::' + thisLabel.split('::')[1];
						//newObject.$$haskKey = contactNames[i].$$hashKey;
						searchNames.push(newObject);
					}
				}
			}
			$scope.$evalAsync(function () {
				if (!table || !contactNames) {
					fields.contactSearchNames = [];
				} else if (searchNames) {
					fields.contactSearchNames = searchNames;
				}
			});
		}

		function updateFieldValue(source, id, property, value, type, related) {
			var fieldMap = $scope.settings.fieldMap;
			var fieldMapPosition = getFieldPosition(property, fieldMap);
			var currentSelection =
				$scope.settings.fieldMap[fieldMapPosition].unused;
			//Set new selections
			$scope.settings.fieldMap[fieldMapPosition].data = value;
			updateField(source, id, property, value, type);
			if (related) {
				$scope.settings.fieldMap[
					getFieldPosition(related, fieldMap)
				].data = value;
				updateField(source, id, related, value, type);
			}
			function getFieldPosition(property, fieldMap) {
				for (var i = 0; i < fieldMap.length; i++) {
					if (property === fieldMap[i].name) {
						return i;
					}
				}
			}
		}

		function toggleField(source, id, property, value, type, related) {
			// remove "Map from type as we don't reference the data that way"
			const typeFixed = type.replace(/Map/g, '');
			const fieldMap = $scope.settings.fieldMap;

			let fieldMapPosition;
			let currentSelection;

			let newValue;

			if (property === 'useAssignedResources') {
				property = 'resource';
				newValue = 'AssignedResources';
				updateSource(
					source,
					null,
					'useAssignedResources',
					!source.useAssignedResources,
					'settings',
					true
				);
				fieldMapPosition = getFieldPosition(property, fieldMap);
				// Clear any potential values in related field mapping
				updateRelatedFieldData('');
				// Make sure that resource is not in unused map
				updateField(source, null, property, null, 'unusedMap');
				// Map resource field to dummy value (we ignore this mapping but need it so DayBack thinks it's mapped)
				updateField(source, id, 'resource', 'resource', 'fieldMap');
			} else {
				fieldMapPosition = getFieldPosition(property, fieldMap);
				currentSelection =
					$scope.settings.fieldMap[fieldMapPosition][typeFixed];

				newValue = !currentSelection;

				if (property === 'resource' && type === 'relatedValueMap') {
					updateRelatedFieldData(newValue);
				}
				$scope.settings.fieldMap[fieldMapPosition][typeFixed] =
					newValue;
			}

			updateField(source, id, property, newValue, type);

			if (related) {
				$scope.settings.fieldMap[getFieldPosition(related, fieldMap)][
					typeFixed
				] = !currentSelection;
				updateField(source, id, related, newValue, type);
			}

			function updateRelatedFieldData(value) {
				//clear values on toggle
				$scope.settings.fieldMap[fieldMapPosition - 1].data = '';
				$scope.settings.fieldMap[fieldMapPosition].data = '';
				//clear values in Firebase too
				updateField(source, id, 'resourceID', '', 'fieldMap');
				updateField(source, id, 'resource', '', 'fieldMap');
				//show/hide resource Id
				$scope.settings.relatedValues.resourceID = value;
			}

			function getFieldPosition(property, fieldMap) {
				for (var i = 0; i < fieldMap.length; i++) {
					if (property === fieldMap[i].name) {
						return i;
					}
				}
			}
		}

		function updateField(source, id, property, value, type) {
			var key;
			$scope.settings.showMappingTestResults = false;
			if (
				property === 'contactID' ||
				property === 'projectID' ||
				property === 'resourceID'
			) {
				if (
					$scope.settings.sourceType === 4 ||
					$scope.settings.sourceType === 10
				) {
					//we need to change true for related Ids to the Id field here if its populated
					var adjustedValue = value;
					if (property === 'resourceID') {
						for (
							var i = 0;
							i < $scope.settings.fieldMap.length;
							i++
						) {
							if (
								$scope.settings.fieldMap[i].name ===
								'resourceID'
							) {
								adjustedValue =
									$scope.settings.fieldMap[i].data;
								break;
							}
						}
					}
					if (adjustedValue !== true && adjustedValue !== false) {
						updateRelated(
							property,
							adjustedValue,
							source,
							source.resourceObjects || {},
							true
						);
					}
				} else if ($scope.settings.sourceType === 8) {
					updateFMJSRelated(property, value, source.tableName);
					if (value) {
						try {
							key =
								$scope.settings.fileMakerJSFields[
									source.tableName
								].primaryKeys[source.tableName][value][0];
						} catch (error) {
							key = null;
						}

						if (key) {
							if (property === 'contactID') {
								updateSource(
									$scope.settings.selectedSource,
									null,
									'contactPrimaryKey',
									key,
									'settings'
								);
							} else {
								updateSource(
									$scope.settings.selectedSource,
									null,
									'projectPrimaryKey',
									key,
									'settings'
								);
							}
						}
					}
				}
			}
			manageConfig.updateSource(source, id, property, value, type);
		}

		function getContactObjects(source) {
			var contactObjects = manageConfig.transformObjects(
				source.contactObjects
			);
			$scope.settings.contactObjects = contactObjects;
		}

		function getResourceObjects(source) {
			var resourceObjects = manageConfig.transformObjects(
				source.resourceObjects
			);
			$scope.settings.resourceObjects = resourceObjects;
		}

		function getProjectObjects(source) {
			var projectObjects = manageConfig.transformObjects(
				source.projectObjects
			);
			$scope.settings.projectObjects = projectObjects;
		}

		function getCustomFields(source) {
			var customFields = manageConfig.transformObjects(
				source.customFields
			);
			$scope.settings.customFields = customFields;
		}

		function getCustomActions(source) {
			var customActions = manageConfig.transformObjects(
				source.customActions
			);
			$scope.settings.customActions = customActions;
		}

		function getEventActions(source) {
			var eventActions = manageConfig.transformObjects(
				source.eventActions
			);
			$scope.settings.eventActions = eventActions;
			//Create drop down type selections

			$scope.settings.eventActionTypes =
				seedcodeCalendar.get('eventActionTypes');
		}

		function getCalendarActions() {
			manageSettings.getCalendarActions(processActions);
			function processActions(actions) {
				$scope.settings.calendarActions =
					manageConfig.transformObjects(actions);
				$scope.settings.calendarActionTypes = seedcodeCalendar.get(
					'calendarActionTypes'
				);
				$scope.settings.appTypes = getAppTypes();
			}
		}

		function updateCalendarAction(
			calendarActions,
			id,
			child,
			property,
			value
		) {
			// Make sure we have some calendar actions before using them. Need to assign object because we want it to be mutable
			if (!seedcodeCalendar.get('calendarActions')) {
				seedcodeCalendar.init('calendarActions', {}, true);
			}

			const originalActions = seedcodeCalendar.get('calendarActions');
			const changedAction = calendarActions.find((action) => {
				return action.id === id;
			});

			// If this is a new type, add that to the original actions
			if (!originalActions[changedAction.type]) {
				originalActions[changedAction.type] = {};
			}

			// If this is a new action, add that to the original actions
			if (!originalActions[changedAction.type][id]) {
				originalActions[changedAction.type][id] = {};
			}

			//update properties of the changed action besides the angular $$hashkey
			for (const property of Object.keys(changedAction)) {
				if (property !== '$$hashKey') {
					originalActions[changedAction.type][id][property] =
						changedAction[property];
				}
			}

			var path = child ? 'calendarActions/' + id : 'calendarActions';
			child = child ? child : id;
			manageConfig.updateObject(
				calendarActions,
				id,
				path,
				child,
				property,
				value
			);
		}

		function addContactObjects(contactObjects, source) {
			var dataMap = manageConfig.contactObjectsDataMap();
			var sources = seedcodeCalendar.get('sources');
			var sourcePosition = manageConfig.getSourcePosition(
				sources,
				source
			);
			// var target = 'sources/' + sourcePosition + '/customActions';
			var target = 'sources/' + source.id + '/contactObjects';

			//Make sure that we have a contact object
			if (!sources[sourcePosition].contactObjects) {
				sources[sourcePosition].contactObjects = {};
			}
			var data = manageConfig.addObject(
				contactObjects,
				target,
				dataMap,
				source.contactObjects
			);
		}

		function addProjectObjects(projectObjects, source) {
			var dataMap = manageConfig.projectObjectsDataMap();
			var sources = seedcodeCalendar.get('sources');
			var sourcePosition = manageConfig.getSourcePosition(
				sources,
				source
			);
			var target = 'sources/' + source.id + '/projectObjects';

			//Make sure that we have an customActions object
			if (!sources[sourcePosition].projectObjects) {
				sources[sourcePosition].projectObjects = {};
			}
			var data = manageConfig.addObject(
				projectObjects,
				target,
				dataMap,
				source.projectObjects
			);
		}

		function addResourceObjects(resourceObjects, source, relatedField) {
			var dataMap = manageConfig.resourceObjectsDataMap();
			var sources = seedcodeCalendar.get('sources');
			var sourcePosition = manageConfig.getSourcePosition(
				sources,
				source
			);
			var target = 'sources/' + source.id + '/resourceObjects';

			//Make sure that we have an customActions object
			if (!sources[sourcePosition].resourceObjects) {
				sources[sourcePosition].resourceObjects = {};
			}
			var data = manageConfig.addObject(
				resourceObjects,
				target,
				dataMap,
				source.resourceObjects
			);
			//update the key field as that will be passed in based on context
			updateSource(
				source,
				data.id,
				'keyField',
				relatedField,
				'resourceObjects'
			);
		}

		function addCustomField(customFields, source, scroll) {
			$scope.settings.selectField = false;
			var config = seedcodeCalendar.get('config');
			var dataMap = manageConfig.customFieldDataMap();
			if (source.sourceTypeID === 8) {
				//use an incremental / predictable id for FileMakerJS Custom Fields
				dataMap.id.defaultValue =
					FileMakerJSCustomFieldId(customFields);
			}
			// var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var target = 'sources/' + sourcePosition + '/customActions';

			var target = source.isSchedule
				? 'sources/' +
					source.parentID +
					'/' +
					source.id +
					'/customFields'
				: 'sources/' + source.id + '/customFields';

			//Make sure that we have a customFields object
			// if (!sources[sourcePosition].customFields) {
			// 	sources[sourcePosition].customFields = {};
			// }
			if (!source.customFields) {
				source.customFields = {};
			}
			var data = manageConfig.addObject(
				customFields,
				target,
				dataMap,
				source.customFields
			);

			//scroll to the bottom of the div to see the new field
			changeCustomFieldFormat(data.formatas, data);
			if (scroll) {
				$scope.settings.focusField = true;
				$scope.settings.scrollField = true;
			}
		}

		function FileMakerJSCustomFieldId(customFields) {
			var id;
			if (customFields.length === 0) {
				id = 'dbk-additionalField-01';
			} else {
				var lastCustomField = customFields[customFields.length - 1];
				var lastId = lastCustomField.id;
				var splitId = lastId.split('-');
				var num = splitId[2];
				num++;
				num = num.toString();
				if (num.length === 1) {
					var z = 0;
					num = z.toString() + num;
				}
				splitId[2] = num;
				id = splitId.join('-');
			}
			return id;
		}

		function addCustomAction(customActions, source, scroll) {
			$scope.settings.selectAction = false;
			var dataMap = manageConfig.customActionDataMap();
			// var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var target = 'sources/' + sourcePosition + '/customActions';
			// var target = 'sources/' + source.id + '/customActions';
			var target = source.isSchedule
				? 'sources/' +
					source.parentID +
					'/' +
					source.id +
					'/customActions'
				: 'sources/' + source.id + '/customActions';

			//Make sure that we have an customActions object
			if (!source.customActions) {
				source.customActions = {};
			}
			var data = manageConfig.addObject(
				customActions,
				target,
				dataMap,
				source.customActions
			);

			//Scroll to our new selection if specified
			if (scroll) {
				$scope.settings.focusAction = true;
				$scope.settings.scrollAction = true;
			}
		}

		function addEventAction(eventActions, source) {
			var dataMap = manageConfig.customActionDataMap('eventClick');
			// var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var target = 'sources/' + sourcePosition + '/eventActions';
			// var target = 'sources/' + source.id + '/eventActions';
			var target = source.isSchedule
				? 'sources/' +
					source.parentID +
					'/' +
					source.id +
					'/eventActions'
				: 'sources/' + source.id + '/eventActions';

			//Make sure that we have an eventActions object
			if (!source.eventActions) {
				source.eventActions = {};
			}
			manageConfig.addObject(
				eventActions,
				target,
				dataMap,
				source.eventActions
			);
		}

		function addCalendarAction(calendarActions) {
			var dataMap = manageConfig.calendarActionDataMap(
				'beforeCalendarRendered'
			);

			var target = 'calendarActions';

			manageConfig.addObject(calendarActions, target, dataMap, null);
		}

		function deleteCalendarAction(calendarActions, calendarAction) {
			// var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var path = 'sources/' + sourcePosition;
			// var path = 'sources/' + source.id;
			var path = 'calendarActions';

			manageConfig.deleteObject(
				calendarActions,
				'',
				'calendarActions',
				calendarAction
			);
		}

		function deleteCustomField(source, customFields, customField) {
			// var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var path = 'sources/' + sourcePosition;
			// var path = 'sources/' + source.id;
			var path = source.isSchedule
				? 'sources/' + source.parentID + '/' + source.id
				: 'sources/' + source.id;
			manageConfig.deleteObject(
				customFields,
				path,
				'customFields',
				customField,
				source.customFields
			);
			$scope.confirmDelete = null;
		}

		function deleteEventAction(source, eventActions, eventAction) {
			// var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var path = 'sources/' + sourcePosition;
			// var path = 'sources/' + source.id;
			var path = source.isSchedule
				? 'sources/' + source.parentID + '/' + source.id
				: 'sources/' + source.id;

			var data = manageConfig.deleteObject(
				eventActions,
				path,
				'eventActions',
				eventAction,
				source.eventActions
			);
		}

		function deleteCustomAction(source, customActions, customAction) {
			// var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var path = 'sources/' + sourcePosition;
			// var path = 'sources/' + source.id;
			var path = source.isSchedule
				? 'sources/' + source.parentID + '/' + source.id
				: 'sources/' + source.id;

			manageConfig.deleteObject(
				customActions,
				path,
				'customActions',
				customAction,
				source.customActions
			);
		}

		function deleteContactObjects(source, contactObjects, contactObject) {
			//var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var path = 'sources/' + sourcePosition;
			var path = 'sources/' + source.id;

			manageConfig.deleteObject(
				contactObjects,
				path,
				'contactObjects',
				contactObject,
				source.contactObjects
			);
		}

		function deleteProjectObjects(source, projectObjects, projectObject) {
			//var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var path = 'sources/' + sourcePosition;
			var path = 'sources/' + source.id;

			manageConfig.deleteObject(
				projectObjects,
				path,
				'projectObjects',
				projectObject,
				source.projectObjects
			);
		}

		function deleteResourceObjects(
			source,
			resourceObjects,
			resourceObject
		) {
			//var sources = seedcodeCalendar.get('sources');
			// var sourcePosition = manageConfig.getSourcePosition(sources, source);
			// var path = 'sources/' + sourcePosition;
			var path = 'sources/' + source.id;

			manageConfig.deleteObject(
				resourceObjects,
				path,
				'resourceObjects',
				resourceObject,
				source.resourceObjects
			);
		}

		function addGroupMember(groupMembers, target) {
			var dataMap = manageConfig.groupMemberDataMap();
			var member = manageConfig.addObject(groupMembers, target, dataMap);
			var memberPosition = manageConfig.getObjectPosition(
				groupMembers,
				member.id
			);
			selectGroupMember(groupMembers[memberPosition]);

			var listScrollContainer = $('.secondColumnInner')[0];
			$timeout(function () {
				listScrollContainer.scrollTop =
					listScrollContainer.scrollHeight -
					listScrollContainer.clientHeight;
			}, 0);

			// Broadcast that the active state of a member has been updated
			$scope.settings.activeMemberCount =
				manageConfig.getActiveMemberCount(groupMembers);
			$rootScope.$broadcast('memberActiveUpdate', groupMembers);
		}

		function updateGroupSetting(
			groupSettings,
			id,
			target,
			property,
			value
		) {
			manageConfig.updateObject(
				groupSettings,
				id,
				'',
				target,
				property,
				value
			);
		}

		function updateGroupMember(groupMembers, id, target, property, value) {
			manageConfig.updateObject(
				groupMembers,
				id,
				target,
				id,
				property,
				value
			);
		}

		function updateGroupAuthSources(
			source,
			sourceID,
			property,
			value,
			target
		) {
			var groupAuthSources = seedcodeCalendar.get('groupAuthSources');
			var config = seedcodeCalendar.get('config');
			groupAuthSources[source.sourceTypeID] = value || null; // Null to prevent false from writing to firebase
			updateSource(source, sourceID, property, value, target);
			if (value) {
				source.sourceTemplate.authFunction(
					authCallback,
					source,
					false,
					true,
					source.sourceTemplate.localSchedules,
					true
				);
			} else if (!value) {
				$scope.settings.selectedSource.groupAuthDetail = null;
				// Deauth
				source.sourceTemplate.deleteSecondaryAuth(
					config.userID,
					config.groupID,
					source.id,
					function (result) {
						// Could report an error here if deauth fails
						// Only possible downside is that the token is left in database
						if (result) {
							// Success
						} else {
							// Failure
						}
					}
				);
			}
			function authCallback(result) {
				$scope.$evalAsync(function () {
					if (!result) {
						// The auth failed or was cancelled. Mark switch as false
						$scope.settings.selectedSource.groupAuthDetail = null;
						source.groupAuthAvailable = false;
						// $scope.settings.selectedSource.groupAuth = true;
						updateGroupAuthSources(
							source,
							sourceID,
							property,
							false,
							target
						);
					} else {
						$scope.settings.selectedSource.groupAuthDetail = {
							name: result.name,
							email: result.accountName,
						};
					}
				});
			}
		}

		function updateUserGroupAuthSources(
			groupAuthSources,
			id,
			memberID,
			property,
			value
		) {
			var target = 'members/' + memberID;

			if (!groupAuthSources.data) {
				groupAuthSources.data = {};
			}
			// Set value to inverse of itself to toggle
			groupAuthSources.data[id] = !value;

			manageConfig.updateObject(
				groupAuthSources.data,
				id,
				target,
				'userGroupAuthSources',
				property,
				groupAuthSources.data[id] || null
			);
		}

		function deleteGroupMember(groupMembers, target, member) {
			manageConfig.removeMemberUser(
				groupMembers,
				target,
				member,
				function (result) {
					if (result) {
						$scope.$evalAsync(function () {
							$scope.settings.activeMemberCount =
								manageConfig.getActiveMemberCount(
									$scope.settings.groupMembers
								);
							$scope.settings.selectedGroupMember = null;
							$scope.settings.memberSettings = null;
							$rootScope.$broadcast(
								'memberActiveUpdate',
								groupMembers
							);
						});
					} else {
						const title = 'Delete User Failed';
						const message =
							'Failed to retrieve user from the database so it could not be deleted';
						utilities.showModal(
							title,
							message,
							'OK',
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							true
						);
					}
				}
			);
		}

		function getUserToken(member) {
			return manageConfig.createUserToken(member.account, member.userID);
		}

		function selectGroupMember(member) {
			var dataMap = manageConfig.groupMemberDataMap();

			$scope.settings.groupAuthSources =
				seedcodeCalendar.get('groupAuthSources');

			$scope.settings.memberMessage = null;
			$scope.settings.selectedGroupMember = member;
			$scope.settings.memberSettings = manageConfig.transformObjectData(
				member,
				dataMap
			);

			$scope.settings.selectedUserToken = getUserToken(member);
		}

		function getGroupSettings() {
			manageConfig.getGroupSettings(processGroup);
			function processGroup(result) {
				var dataMap = manageConfig.groupDataMap();
				var settings = manageConfig.transformObjectData(
					result,
					dataMap
				);
				$scope.$apply(function () {
					$scope.settings.group = result;
					$scope.settings.groupSettings = settings;
				});
			}
		}

		function getGroupMembers() {
			var memberPosition;
			var dataMap = manageConfig.groupMemberDataMap();
			manageSettings.getGroupMembers(processMembers);
			function processMembers(members) {
				$scope.settings.groupMembers = manageConfig.transformObjects(
					members,
					dataMap
				);
				$scope.settings.activeMemberCount =
					manageConfig.getActiveMemberCount(
						$scope.settings.groupMembers
					);

				// Reselect current member so we can make sure anything selected is up to date
				if ($scope.settings.selectedGroupMember) {
					memberPosition = manageConfig.getObjectPosition(
						$scope.settings.groupMembers,
						$scope.settings.selectedGroupMember.id
					);
					selectGroupMember(
						$scope.settings.groupMembers[memberPosition]
					);
				}
			}
		}

		function getLanguages() {
			var languages = manageTranslations.defaultTranslations;
			var result = [];
			for (var property in languages) {
				result.push({
					id: languages[property].id,
					name: languages[property].name,
				});
			}
			return result;
		}

		function selectLanguageTranslation(id) {
			//$scope.selectedLanguage = id;
			manageTranslations.getTranslations(id, true, onGetTranslations);
			function onGetTranslations(result) {
				const omit = [
					'Public bookmarks expire essentials plan',
					'Service will end days unless payment is received',
					'Service will end day unless payment is received',
					'Subscription overdue message admin',
					'Subscription overdue message non admin',
				];

				const translations = {};
				const resultKeys = Object.keys(result);
				for (const key of resultKeys) {
					if (!omit.includes(key)) {
						translations[key] = result[key];
					}
				}

				$scope.$apply(function () {
					$scope.settings.selectedLanguage = id;
					$scope.settings.translations = translations;
				});
			}
		}

		function updateTranslation(id, property, value) {
			var translation = {};
			translation[property] = value;
			manageSettings.setTranslations(id, translation, null);
		}

		function updateTheme(cssString) {
			manageTheme.setThemeStyles(cssString);
			$scope.settings.themeNotSaved = isThemeEdited();
		}

		function isThemeEdited() {
			return (
				manageTheme.getThemeStyles() !==
				manageTheme.getThemeStylesBackup()
			);
		}
		function saveTheme(cssString) {
			manageTheme.saveTheme(cssString);
			$scope.settings.themeNotSaved = false;
		}

		function updatePercentComplete() {
			$timeout(function () {
				$scope.percentComplete = 33;
				if (
					$scope.settings.sources.length > 0 ||
					$scope.settings.groupMembers.length > 1
				) {
					$scope.percentComplete = 70;
				}
				if (
					$scope.settings.sources.length > 0 &&
					$scope.settings.groupMembers.length > 1
				) {
					$scope.percentComplete = 100;
				}
			}, 1200);
		}

		function toggleAutomaticLicenseManagement(
			automaticLicenseManagement,
			propertyName,
			activationConfig
		) {
			var title = automaticLicenseManagement
				? 'Automatic License Management Enabled'
				: 'Automatic License Management Disabled';
			var message;

			if (automaticLicenseManagement) {
				message =
					'Enabling automatic license management will adjust your billing as you add, remove, and activate users. Your payment method on file will be debited, or your subscription credited, with each transaction.';
			} else {
				message =
					'Disabling automatic license management means you\'ll need to click "manage subscription" to the right in order to increase or decrease the number of users on your DayBack license.';
			}

			message +=
				' <div><a href="https://docs.dayback.com/article/268-automatic-license-management" target="_blannk">More info...</a></div>';

			utilities.showModal(
				title,
				message,
				'Cancel',
				function () {
					activationConfig[propertyName] =
						!automaticLicenseManagement;
				},
				'OK',
				function () {
					manageConfig.updateSetting(
						'manageUserCount',
						automaticLicenseManagement
					);
				},
				null,
				null,
				null,
				null,
				true
			);
		}

		// Functions for displaying our tests of FileMaker Server configs via fmxj
		function getTestConnection(selectedSource) {
			var user = daybackIO.getUser();
			var connection = {
				file: selectedSource.fileName,
				layout: selectedSource.layoutName,
				relay: {
					php: selectedSource.phpRelay,
					server: selectedSource.serverAddress,
					protocol: selectedSource.protocol,
					port: selectedSource.port,
					user: user.email,
				},
			};
			return connection;
		}

		function writeDownload(n) {
			if ($scope.serverTest.details == null) {
				$scope.serverTest.details = '\n';
			}
			$timeout(function () {
				$scope.serverTest.details =
					$scope.serverTest.details +
					'\n\n' +
					n +
					' bytes downloaded\n';
			}, 0);
		}

		function writeResultDBNames(js, num) {
			var error;
			//We have tested the php relay file by just accessing this test
			$scope.serverTest.phpRelayTested = true;
			// Check to see if we are getting an http error. This would indicate we can't communicate with our php relay file
			if (
				js[0] &&
				js[0].ERRORCODE &&
				js[0].ERRORCODE.indexOf('http') > -1
			) {
				$scope.serverTest.DBNamesTested = false;

				error = js[0].ERRORCODE.split('-')[1];
				if (error === '401') {
					$scope.serverTest.phpRelayAccount = false;
					$scope.serverTest.phpRelay = true;
				} else {
					$scope.serverTest.phpRelayAccount = true;
					$scope.serverTest.phpRelay = false;
				}
			} else {
				$scope.serverTest.phpRelay = true;
				$scope.serverTest.phpRelayAccount = true;
				$scope.serverTest.DBNamesTested = true;
			}
			var indent = 4; // JSON indent
			var display = JSON.stringify(js.slice(0, num), null, indent);
			var test = $scope.settings.selectedSource.fileName;
			var serverTestPassed;
			//Normalize file name so we don't include .fmp12
			if (test.slice(-6).toLowerCase() === '.fmp12') {
				test = test.slice(0, -6);
			}
			//Loop through file name results to match file. Doing this gives us an exact match and prevents partial name matches
			for (var i = 0; i < js.length; i++) {
				if (test === js[i].DATABASE_NAME) {
					serverTestPassed = true;
				}
			}
			$timeout(function () {
				$scope.serverTest.DBNames = serverTestPassed;
				$scope.serverTest.details =
					$scope.serverTest.details +
					'\nFiles Available:\n\n' +
					display;
				if (serverTestPassed) {
					testConfigPart2();
				}
			}, 0);
		}

		function writeResultLayoutNames(js, num) {
			// define handler for results.
			var indent = 4; // JSON indent
			var display = JSON.stringify(js.slice(0, num), null, indent);
			var test = $scope.settings.selectedSource.layoutName;
			$timeout(function () {
				$scope.serverTest.layoutNameTested = true;
				$scope.serverTest.layoutName = display.indexOf(test) > -1;
				$scope.serverTest.details =
					$scope.serverTest.details +
					'\nLayouts Available in ' +
					$scope.settings.selectedSource.fileName +
					':\n\n' +
					display;
				testConfigPart3();
			}, 0);
		}

		function writeResultFieldNames(js, num) {
			// define handler for results.
			var indent = 4; // JSON indent
			var display = JSON.stringify(js.slice(0, num), null, indent);
			var fieldMap = $scope.settings.fieldMap;
			var unusedMap = $scope.settings.selectedSource.unusedMap;
			var customFields = $scope.settings.selectedSource.customFields;
			var fieldNameMapped;
			// loop through the fieldMap and make sure each mapped onject is in the field list returned as 'js'
			$scope.serverTest.fieldNames = true;
			$scope.serverTest.fieldNamesFailedList = '';

			for (var key in fieldMap) {
				if (fieldMap.hasOwnProperty(key)) {
					var val = fieldMap[key];
					fieldNameMapped = val.data;

					//Don't test if the mapping for the field has been marked unused
					if (unusedMap && unusedMap[val.name]) {
						continue;
					}

					if (display.indexOf('"' + fieldNameMapped + '":') == -1) {
						$scope.serverTest.fieldNames = false;
						if (fieldNameMapped) {
							$scope.serverTest.fieldNamesFailedList =
								$scope.serverTest.fieldNamesFailedList +
								fieldNameMapped +
								'\n';
						}
					}
				}
			}

			//Test for custom fields
			for (var property in customFields) {
				if (customFields.hasOwnProperty(property)) {
					var item = customFields[property];
					fieldNameMapped = item.field;

					if (display.indexOf('"' + fieldNameMapped + '":') == -1) {
						$scope.serverTest.fieldNames = false;
						if (fieldNameMapped) {
							$scope.serverTest.fieldNamesFailedList =
								$scope.serverTest.fieldNamesFailedList +
								fieldNameMapped +
								'\n';
						}
					}
				}
			}
			$timeout(function () {
				$scope.serverTest.fieldNamesTested = true;
				$scope.serverTest.details =
					$scope.serverTest.details +
					'\nFields Available on Layout ' +
					$scope.settings.selectedSource.layoutName +
					':\n\n' +
					display;
			}, 0);
		}

		function customFieldPickerSF(format) {
			var baseList = $scope.customFields;
			var dataTypeList;

			if (format === 'edit') {
				dataTypeList = $scope.contactFields;
			} else if (format === 'date') {
				dataTypeList = utilities.mergeArrays(
					$scope.customTimestampFields,
					$scope.customDateFields,
					'apiName'
				);
			} else if (format === 'timestamp') {
				dataTypeList = $scope.customTimestampFields;
			} else if (
				format === 'number' ||
				format === 'currency' ||
				format === 'percent'
			) {
				dataTypeList = $scope.numberFields;
			} else if (format === 'checkbox') {
				dataTypeList = $scope.allDayFields;
			} else if (format === 'select' || format === 'drop') {
				dataTypeList = $scope.picklistFields;
			}

			return utilities.mergeArrays(dataTypeList, baseList, 'apiName');
		}

		function updateCustomFieldDataFormat(
			data,
			fieldList,
			fieldProperty,
			typeProperty
		) {
			if (!data || !fieldList) {
				return;
			}

			for (var property in fieldList) {
				if (
					fieldList[property] &&
					data.field === fieldList[property][fieldProperty]
				) {
					data.dataFormat = fieldList[property][typeProperty];
					return data.dataFormat;
				}
			}
		}

		function changeCustomFieldFormat(format, data) {
			var defaults = {};

			if (!format) {
				defaults.formatas = 'edit'; //Set default field format
			}

			if (format === 'currency') {
				defaults.numberLabel = '$';
				defaults.numberLabelPosition = 'before';
				defaults.decimalCharacter = '.';
				defaults.decimalPlaces = 2;
				defaults.thousandsSeparator = ',';
			} else if (format === 'number') {
				defaults.numberLabel = null;
				defaults.numberLabelPosition = null;
				defaults.decimalCharacter = '.';
				defaults.decimalPlaces = null;
				defaults.thousandsSeparator = ',';
			} else if (format === 'percent') {
				defaults.numberLabel = '%';
				defaults.numberLabelPosition = 'after';
				defaults.decimalCharacter = '.';
				defaults.decimalPlaces = null;
				defaults.thousandsSeparator = ',';
			} else {
				defaults.numberLabel = null;
				defaults.numberLabelPosition = null;
				defaults.decimalCharacter = null;
				defaults.decimalPlaces = null;
				defaults.thousandsSeparator = null;
			}
			for (var property in defaults) {
				data[property] = defaults[property];
				updateSource(
					$scope.settings.selectedSource,
					data.id,
					property,
					defaults[property],
					'customFields'
				);
			}
		}

		function getCustomFieldFormats() {
			return [
				{id: 'edit', label: 'Edit Box'},
				{id: 'number', label: 'Number'},
				{id: 'currency', label: 'Currency'},
				{id: 'percent', label: 'Percent'},
				{id: 'url', label: 'URL'},
				{id: 'checkbox', label: 'Checkbox'},
				{id: 'radio', label: 'Radio Button Set'},
				// {id: 'drop', label: 'Drop Down List'},
				{id: 'select', label: 'Picklist'},
				{id: 'date', label: 'Date Picker'},
				{id: 'timestamp', label: 'Date & Time Picker'},
			];
		}

		function getEventTypes() {
			return [
				{id: 'editable', label: 'Editable'},
				{id: 'readonly', label: 'Read Only'},
				{id: 'shared', label: 'Shared'},
			];
		}

		function getAppTypes() {
			return [
				{id: 'app', label: 'App'},
				{id: 'shares', label: 'Shares'},
			];
		}

		function changeSidebarPanel(name, change) {
			if (!$scope.sidebar) {
				$scope.sidebar = {};
			}
			if (!$scope.sidebar.panel) {
				$scope.sidebar.panel = {};
			}

			$scope.sidebar.panel[name] = change;
		}

		function testFileMakerContactData() {
			var contactData = $scope.settings.contactData;
			$scope.contactTest = {};
			var searchLayout;
			var navigationLayout;
			var searchField;
			var displayField;
			var contactKey = $scope.settings.selectedSource.fieldMap.contactID;
			var tableName = $scope.settings.selectedSource.tableName;
			if (
				$scope.settings.fileMakerJSFields[tableName].primaryKeys &&
				contactKey
			) {
				var primaryKey =
					$scope.settings.fileMakerJSFields[tableName].primaryKeys[
						tableName
					][contactKey][0];
			}
			//make sure all fields are populated
			for (var i = 0; i < contactData.length; i++) {
				if (!contactData[i].data) {
					$scope.contactTest.contactTested = true;
					$scope.contactTest.contact = false;
					$scope.contactTest.details = 'All fields are required.';
					return;
				}
				if (contactData[i].name === 'searchLayout') {
					searchLayout = contactData[i].data;
				}
				if (contactData[i].name === 'navigationLayout') {
					navigationLayout = contactData[i].data;
				}
				if (contactData[i].name === 'searchField') {
					searchField = contactData[i].data;
				}
				if (contactData[i].name === 'displayField') {
					displayField = contactData[i].data;
				}
			}

			//validate search layout
			fileMakerLayoutTable(searchLayout, processSearchLayout);

			function processSearchLayout(data) {
				var result;
				var tableReturned;
				if (data.result.indexOf('"layoutData"') !== -1) {
					result = JSON.parse(data.result);
					tableReturned = result.table;
				} else {
					tableReturned = data.result;
				}

				//do a search to to make sure we don;t get an error
				var request = {};
				request.layout = searchLayout;
				request.table = tableReturned;
				request.searchField = searchField;
				request.displayField = displayField.split('::')[1];
				request.idField = $scope.settings.selectedSource
					.contactPrimaryKey
					? $scope.settings.selectedSource.contactPrimaryKey.split(
							'::'
						)[1]
					: '';
				request.criteria = '*';

				var payload = {};
				payload.script = 'Look Up Related - DayBack';
				payload.request = request;
				//define first callback
				var callbackId = utilities.generateUID();
				dbk_fmFunctions[callbackId] = processSearch;
				payload.callback = callbackId;
				payload.dbk = true;
				utilities.fileMakerCall(payload);

				function processSearch(data) {
					$scope.$evalAsync(function () {
						if (data.status === 200) {
							var payload = data.payload;
							$scope.contactTest.contactTested = true;
							$scope.contactTest.contact = true;
							$scope.contactTest.details =
								'Everything looks good.';
						} else {
							$scope.contactTest.contactTested = true;
							$scope.contactTest.contact = false;
							$scope.contactTest.details =
								'Test search failed using ' +
								searchField +
								' on the ' +
								searchLayout +
								'layout';
						}
					});
				}
			}
		}

		function testFileMakerProjectData() {
			var projectData = $scope.settings.projectData;
			$scope.projectTest = {};
			var searchLayout;
			var navigationLayout;
			var searchField;
			var displayField;
			var projectKey = $scope.settings.selectedSource.fieldMap.projectID;
			var tableName = $scope.settings.selectedSource.tableName;
			var targetTable;
			if (
				$scope.settings.fileMakerJSFields[tableName].primaryKeys &&
				projectKey
			) {
				var primaryKey =
					$scope.settings.fileMakerJSFields[tableName].primaryKeys[
						tableName
					][projectKey][0];
			}
			//make sure all fields are populated
			for (var i = 0; i < projectData.length; i++) {
				if (!projectData[i].data) {
					$scope.projectTest.projectTested = true;
					$scope.projectTest.project = false;
					$scope.projectTest.details = 'All fields are required.';
					return;
				}
				if (projectData[i].name === 'searchLayout') {
					searchLayout = projectData[i].data;
				}
				if (projectData[i].name === 'navigationLayout') {
					navigationLayout = projectData[i].data;
				}
				if (projectData[i].name === 'searchField') {
					searchField = projectData[i].data;
				}
				if (projectData[i].name === 'displayField') {
					displayField = projectData[i].data;
				}
			}

			//validate search layout
			fileMakerLayoutTable(searchLayout, processSearchLayout);

			function processSearchLayout(data) {
				var result;
				var tableReturned;
				if (data.result.indexOf('"layoutData"') !== -1) {
					result = JSON.parse(data.result);
					tableReturned = result.table;
				} else {
					tableReturned = data.result;
				}

				//do a search to to make sure we don;t get an error
				var request = {};
				request.layout = searchLayout;
				request.table = tableReturned;
				request.searchField = searchField;
				request.displayField = displayField.split('::')[1];
				request.idField = $scope.settings.selectedSource
					.projectPrimaryKey
					? $scope.settings.selectedSource.projectPrimaryKey.split(
							'::'
						)[1]
					: '';
				request.criteria = '*';

				var payload = {};
				payload.script = 'Look Up Related - DayBack';
				payload.request = request;
				//define first callback
				var callbackId = utilities.generateUID();
				dbk_fmFunctions[callbackId] = processSearch;
				payload.callback = callbackId;
				payload.dbk = true;
				utilities.fileMakerCall(payload);

				function processSearch(data) {
					$scope.$evalAsync(function () {
						if (data.status === 200) {
							var payload = data.payload;
							$scope.projectTest.projectTested = true;
							$scope.projectTest.project = true;
							$scope.projectTest.details =
								'Everything looks good.';
						} else {
							$scope.projectTest.projectTested = true;
							$scope.projectTest.project = false;
							$scope.projectTest.details =
								'Test search failed using ' +
								searchField +
								' on the ' +
								searchLayout +
								'layout';
						}
					});
				}
			}
		}

		function fileMakerLayoutTable(layout, callback) {
			var payload = {};
			payload.script = 'Get Layout Table - DayBack';
			var request = {};
			request.layout = layout;
			payload.request = request;
			var callbackId = utilities.generateUID();
			dbk_fmFunctions[callbackId] = callback;
			payload.callback = callbackId;
			payload.dbk = true;
			utilities.fileMakerCall(payload);
		}

		function setFileMakerTable(data) {
			var table;
			if (data.status === 200) {
				if (data.result.indexOf(':[') !== -1) {
					var result = JSON.parse(data.result);
					table = result.table;
					var newData = {};
					newData.status = 200;
					newData.payload = result;
					$scope.settings.selectedSource.tableName = table;
					processFileMakerJSConfig(newData);
				} else {
					table = data.result;
					$scope.settings.selectedSource.tableName = table;
				}
				var theSetting;
				for (var i = 0; i < $scope.settings.source.length; i++) {
					if ($scope.settings.source[i].name === 'tableName') {
						theSetting = $scope.settings.source[i];
						//theSetting.options = [];
						$scope.$evalAsync(function () {
							//if(!theSetting.options) {
							theSetting.options = [];
							theSetting.options.push({id: '', label: ''});
							//}
							theSetting.options.push({id: table, label: table});
							updateSource(
								$scope.settings.selectedSource,
								null,
								'tableName',
								table,
								'settings'
							);
							theSetting.data = table;
						});
					}
				}
			}
			if ($scope.settings.action) {
				if (
					$scope.settings.action === 'create' &&
					$scope.settings.entity === 'customField'
				) {
					//new custom field
					settingsTab('Custom Fields');
					addCustomField(
						$scope.settings.customFields,
						$scope.settings.selectedSource,
						true
					);
				} else if (
					$scope.settings.action === 'create' &&
					$scope.settings.entity === 'customButton'
				) {
					settingsTab('Actions & Buttons');
					addCustomAction(
						$scope.settings.customActions,
						$scope.settings.selectedSource,
						true
					);
				} else if ($scope.settings.section) {
					settingsTab($scope.settings.section);
				}
				$scope.settings.action = null;
				$scope.settings.entity = null;
				$scope.settings.selection = null;
			}

			loadCustomFieldPicklistsFM();
		}

		function testFileMakerJSFieldMapping() {
			$scope.mappingTest = {};
			if (!$scope.settings.selectedSource.unusedMap) {
				$scope.settings.selectedSource.unusedMap = {};
			}

			var sourceMap = $scope.settings.sourceTemplate.fieldMap;
			var fieldMap = $scope.settings.selectedSource.fieldMap;
			var unusedMap = $scope.settings.selectedSource.unusedMap;
			var hideMap = $scope.settings.selectedSource.hideMap;
			var customFields = $scope.settings.selectedSource.customFields;
			var field;
			var message;

			//see if the table is valid
			var tableFields =
				$scope.settings.fileMakerJSFields[
					$scope.settings.selectedSource.tableName
				];
			if (!tableFields) {
				$scope.mappingTest.mappingTested = true;
				message =
					$scope.settings.selectedSource.tableName +
					' is missing required fields or is not a valid table name. Please go to the Calendar Info tab and make sure the layout test is valid.';
				$scope.mappingTest.table = false;
				$scope.mappingTest.details = message;
				return;
			}

			//make sure a field is still valid
			var fields = [];
			var allFields = tableFields.all;
			var relatedNames =
				tableFields.relatedNames &&
				tableFields.relatedNames[
					$scope.settings.selectedSource.tableName
				];
			if (relatedNames) {
				var contactNames = relatedNames[fieldMap['contactID']];
				var projectNames = relatedNames[fieldMap['projectID']];
				if (contactNames) {
					allFields = allFields.concat(contactNames);
				}
				if (projectNames) {
					allFields = allFields.concat(projectNames);
				}
			}
			for (field in fieldMap) {
				var thisField = fieldMap[field];
				var result = false;
				for (var i = 0; i < allFields.length; i++) {
					if (
						unusedMap[field] ||
						hideMap[field] ||
						(allFields[i].id && allFields[i].id === thisField)
					) {
						result = true;
						break;
					}
				}
				if (!result && fieldMap[field]) {
					fields.push(fieldMap[field]);
				}
			}

			if (fields.length > 0) {
				$scope.mappingTest.mappingTested = true;
				fields = fields.join(', ');
				message =
					'Dayback cannot find these fields in the table occurrence following fields' +
					'\n' +
					fields;
				$scope.mappingTest.table = false;
				$scope.mappingTest.details = message;
				return;
			}

			//validate our required fields are mapped
			fields = [];
			for (field in sourceMap) {
				const required = processTransformedSourceItem(
					$scope.settings.selectedSource,
					sourceMap[field].required
				);
				if (required && !fieldMap[field]) {
					fields.push(sourceMap[field].displayValue);
				}
			}
			if (fields.length > 0) {
				$scope.mappingTest.mappingTested = true;
				fields = fields.join(', ');
				message =
					'The following required fields are not mapped. Please map these fields to a valid FileMaker field for this table:' +
					'\n' +
					fields;
				$scope.mappingTest.table = false;
				$scope.mappingTest.details = message;
				return;
			}

			//validate our field mapping, e.g. unmapped fields should be marked as unused.
			fields = [];
			for (field in fieldMap) {
				if (!fieldMap[field]) {
					if (!unusedMap[field] && !hideMap[field]) {
						if (
							(field === 'allDay' &&
								$scope.settings.selectedSource.allowAllDay) ||
							field !== 'allDay'
						)
							fields.push(sourceMap[field].displayValue);
					}
				}
			}
			if (fields.length > 0) {
				$scope.mappingTest.mappingTested = true;
				fields = fields.join(', ');
				message =
					'The following fields are not mapped, but are enabled. Please un-check enable or map the field to a valid Filemaker field for this table:' +
					'\n' +
					fields;
				$scope.mappingTest.table = false;
				$scope.mappingTest.details = message;
				return;
			}

			$scope.mappingTest.mappingTested = true;
			$scope.mappingTest.table = true;
			$scope.mappingTest.details = 'FileMaker Response: No Errors';
		}

		function processTransformedSourceItem(source, setting) {
			return typeof setting === 'function' ? setting(source) : setting;
		}

		function testSalesForceObject(successCallback) {
			$scope.objectTest = {};
			$scope.recordTypes = [];
			$scope.settings.updatingDropDowns = true;
			var o = $scope.settings.selectedSource.objectName;
			var contactID = $scope.settings.selectedSource.fieldMap.contactID;
			var projectID = $scope.settings.selectedSource.fieldMap.projectID;
			var resourceID = $scope.settings.selectedSource.fieldMap.resourceID;
			var allowTimes = $scope.settings.selectedSource.allowAllDay;
			if (!o) {
				$scope.settings.updatingDropDowns = false;
				runCallback(false);
				return;
			}
			$scope.settings.sourceTemplate.getFieldData(o, callBack);

			function callBack(d) {
				$scope.$evalAsync(function () {
					$scope.objectTest.objectTested = true;
					if (d[0] && d[0].errorCode) {
						$scope.objectTest.object = false;
						$scope.objectTest.details =
							'Salesforce Response: ' + d[0].message;
						runCallback(false);
						return;
					} else {
						$scope.objectTest.object = true;
						$scope.objectTest.details =
							'Salesforce Response: Valid Object';
						$scope.dateFields = allowTimes
							? buildSelectList(d.dateTimeFields)
							: buildSelectList(d.dateFields);
						$scope.customDateFields = buildSelectList(d.dateFields);
						$scope.customTimestampFields = buildSelectList(
							d.dateTimeFields
						);
						$scope.titleFields = buildSelectList(d.titleFields);
						$scope.statusFields = buildSelectList(d.statusFields);
						if (!resourceID) {
							$scope.resourceFields = buildSelectList(
								d.resourceFields
							);
						}
						$scope.originalResourceFields = buildSelectList(
							d.resourceFields
						);
						$scope.statusFields = buildSelectList(d.statusFields);
						$scope.descriptionFields = buildSelectList(
							d.descriptionFields
						);
						$scope.locationFields = buildSelectList(
							d.locationFields
						);
						$scope.idFields = buildSelectList(d.idFields);
						$scope.allDayFields = buildSelectList(d.allDayFields);
						$scope.unscheduledFields = buildSelectList(
							d.allDayFields
						);
						$scope.tagFields = buildSelectList(d.tagFields);
						$scope.numberFields = buildSelectList(d.numberFields);
						$scope.contactFields = buildSelectList(d.contactFields);
						$scope.picklistFields = buildSelectList(
							d.picklistFields
						);
						$scope.geocodeFields = buildSelectList(d.geocodeFields);
						$scope.customFields = buildSelectList(d.customFields);
						$scope.urlFields = buildSelectList(d.urlFields);
						$scope.textFields = buildSelectList(d.textFields);
						$scope.recordTypes = d.recordTypes;

						//Set custom field select list
						var customFieldFormats = getCustomFieldFormats();
						var customFieldSelectList = {};
						for (var i = 0; i < customFieldFormats.length; i++) {
							customFieldSelectList[customFieldFormats[i].id] =
								customFieldPickerSF(customFieldFormats[i].id);
						}
						$scope.customFieldSelectList = customFieldSelectList;
					}

					runCallback(true);
				});
				//if the id fields are mapped, then let's look up their values.
				if (contactID) {
					$scope.$evalAsync(function () {
						updateRelated(
							'contactID',
							contactID,
							$scope.settings.selectedSource
						);
					});
				}
				if (projectID) {
					$scope.$evalAsync(function () {
						updateRelated(
							'projectID',
							projectID,
							$scope.settings.selectedSource
						);
					});
				}
				if (resourceID) {
					$scope.$evalAsync(function () {
						updateRelated(
							'resourceID',
							resourceID,
							$scope.settings.selectedSource
						);
					});
				}
			}
			function runCallback(returnValue) {
				$scope.settings.updatingDropDowns = false;
				if (successCallback) {
					successCallback(returnValue);
				}
			}
		}

		function buildSelectList(list) {
			if (!list || !list.length) {
				return;
			}
			var noneOption = {
				apiName: '',
			};
			var result = list[0].apiName === '' ? [] : [noneOption];
			for (var i = 0; i < list.length; i++) {
				// list[i].displayValue = list[i].apiName;
				result.push(list[i]);
			}
			return result;
		}

		function optionsByField(property, value) {
			const allowTextFieldMap =
				$scope.settings.selectedSource.allowTextFieldMap ?? {};
			if (property === 'start' || property === 'end') {
				return $scope.dateFields;
			} else if (property === 'allDay') {
				return $scope.allDayFields;
			} else if (property === 'unscheduled') {
				return $scope.allDayFields;
			} else if (property === 'title' || property === 'titleEdit') {
				return $scope.titleFields;
			} else if (property === 'description') {
				return $scope.titleFields;
			} else if (property === 'resource') {
				return $scope.resourceFields;
			} else if (property === 'status') {
				return $scope.statusFields;
			} else if (property === 'tags') {
				return $scope.tagFields;
			} else if (property === 'location') {
				return $scope.locationFields;
			} else if (property === 'geocode') {
				return allowTextFieldMap.geocode
					? $scope.textFields
					: $scope.geocodeFields;
			} else if (
				property === 'contactID' ||
				property === 'projectID' ||
				property === 'resourceID'
			) {
				return $scope.idFields;
			} else if (property === 'contactName') {
				return $scope.contactNameFields;
			} else if (property === 'projectName') {
				return $scope.projectNameFields;
			} else if (
				property === 'featuredImage' ||
				property === 'featuredImageThumbnail'
			) {
				return $scope.urlFields;
			} else if (
				property === 'featuredImageClass' ||
				property === 'featuredImageThumbnailClass'
			) {
				return $scope.textFields;
			}
		}

		function updateRelated(
			property,
			value,
			source,
			resourceObjects,
			isEdit
		) {
			var i;
			var obj;
			var api;
			for (i in $scope.idFields) {
				if (
					value === $scope.idFields[i].apiName &&
					$scope.idFields[i].relationshipName
				) {
					obj = $scope.idFields[i].relationshipName;
					api = $scope.idFields[i].referenceTo;
				}
			}
			if (
				$scope.idFields[i].allReferences.length > 1 ||
				obj === 'What' ||
				obj === 'Who' ||
				obj === 'Owner' ||
				obj === 'CreatedBy' ||
				obj === 'LastModifiedBy' ||
				obj === 'JigsawCompany' ||
				obj === 'DandBCompany' ||
				obj === 'MasteRecord' ||
				obj === 'Parent'
			) {
				var result = [
					{
						apiName: obj + '.Name',
						label: obj + ' Name',
					},
				];
				//we don't need to make an async call, just update the scope var
				if (property === 'contactID') {
					$scope.contactNameFields = result;
					if (isEdit) {
						defaultName(obj, 'contactName');
					}
				} else if (property === 'projectID') {
					$scope.projectNameFields = result;
					if (isEdit) {
						defaultName(obj, 'projectName');
					}
				} else if (
					property === 'resourceID' &&
					$scope.settings.selectedSource.relatedValueMap.resourceID
				) {
					$scope.resourceFields = result;
					if (isEdit) {
						defaultName(obj, 'resource');
					}
				} else if (
					property === 'resourceID' &&
					!$scope.settings.selectedSource.relatedValueMap.resourceID
				) {
					$scope.resourceFields = $scope.originalResourceFields;
					if (isEdit) {
						defaultName(obj, 'resource');
					}
				}
			} else {
				//we need to do a call to SF for these values
				//there is just a single object reference, so we can query that object only
				if (!api) {
					callBack({titleFields: []});
				} else {
					$scope.settings.sourceTemplate.getFieldData(api, callBack);
				}
			}
			//we need to look up the possible related objects for a related resource
			//if there is more than one, we want the user to select which objects are in consideration
			if (property === 'resourceID') {
				//update picklist options for the resource object key (relatedField) options
				//this will need to be updated when we allow multiple resources in field mapping
				$scope.settings.selectedSource.relatedResourceFields = [
					{value: value},
				];
				//look-up the possible objects that can be referenced by this related field
				if (value) {
					$scope.settings.sourceTemplate.getResourceObjects(
						value,
						source,
						resourceObjectOptions
					);
				} else {
					updateResourceObjects(resourceObjects, false);
				}
			}
			function resourceObjectOptions(objects) {
				//create options for resourceObject objects
				if (!$scope.settings.selectedSource.resourceObjectOptions) {
					$scope.settings.selectedSource.resourceObjectOptions = {};
				}
				$scope.settings.selectedSource.resourceObjectOptions[value] =
					[];
				for (var i = 0; i < objects.length; i++) {
					var option = {};
					option.value = objects[i];
					$scope.settings.selectedSource.resourceObjectOptions[
						value
					].push(option);
				}
				//update the default resource object for this key value
				if (resourceObjects) {
					updateResourceObjects(resourceObjects, value);
				}
			}

			function callBack(d) {
				var i;
				var nameField;
				//append our object to the beginning of these reated fields
				for (i in d.titleFields) {
					d.titleFields[i].apiName =
						obj + '.' + d.titleFields[i].apiName;
					if (d.titleFields.nameField) {
						nameField = d.titleFields[i].apiName;
					}
				}
				$scope.$evalAsync(function () {
					if (property === 'contactID') {
						$scope.contactNameFields = d.titleFields;
						if (isEdit) {
							defaultName(obj, 'contactName', nameField);
						}
					} else if (property === 'projectID') {
						$scope.projectNameFields = d.titleFields;
						if (isEdit) {
							defaultName(obj, 'projectName', nameField);
						}
					} else if (
						property === 'resourceID' &&
						$scope.settings.selectedSource.relatedValueMap
							.resourceID
					) {
						$scope.resourceFields = d.titleFields;
						if (isEdit) {
							defaultName(obj, 'resource', nameField);
						}
					} else if (
						property === 'resourceID' &&
						!$scope.settings.selectedSource.relatedValueMap
							.resourceID
					) {
						$scope.resourceFields = $scope.originalResourceFields;
						if (isEdit) {
							defaultName(obj, 'resource', nameField);
						}
					}
				});
			}

			function defaultName(obj, name, nameField) {
				if (!nameField) {
					nameField = 'Name';
				}
				var resourceValue = !obj ? '' : obj + '.' + nameField;
				$scope.$evalAsync(function () {
					updateFieldValue(
						$scope.settings.selectedSource,
						null,
						name,
						resourceValue,
						'fieldMap'
					);
				});
			}

			function updateResourceObjects(resourceObjects, value) {
				var path = 'sources/' + source.id;
				if (!value) {
					manageConfig.updateSourceObject(
						path,
						'resourceObjects',
						null
					);
					return;
				}
				var relatedFields =
					$scope.settings.selectedSource.relatedResourceFields;
				//remove objects that no longer have an active key field
				var newResourceObjects = {};
				for (var resourceObject in resourceObjects) {
					for (var i = 0; i < relatedFields.length; i++) {
						if (
							resourceObjects[resourceObject].keyField ===
							relatedFields[i]
						) {
							newResourceObjects[
								resourceObjects[resourceObject].id
							] = resourceObjects[resourceObject];
						}
					}
				}
				//now create our new resource object for this new key value
				var resourceObject = {};
				var objectId = utilities.generateUID();
				resourceObject.id = objectId;
				resourceObject.keyField = value;
				//determine the default object to use for this resourceObject

				var objects =
					$scope.settings.selectedSource.resourceObjectOptions[value];
				if (objects.length > 1) {
					var o;
					for (o = 0; o < objects.length; o++) {
						if (objects[o].value === 'User') {
							resourceObject.object = objects[o].value;
							resourceObject.objectDisplay = 'Users';
							resourceObject.displayField = 'Name';
							resourceObject.searchField = 'Name';
							break;
						}
					}
					if (!resourceObject.object) {
						for (o = 0; o < objects.length; o++) {
							if (objects[o.value] === 'Account') {
								resourceObject.object = objects[o].value;
								resourceObject.objectDisplay = 'Accounts';
								resourceObject.displayField = 'Name';
								resourceObject.searchField = 'Name';
								break;
							}
						}
					}
				}
				if (!resourceObject.object) {
					resourceObject.object = objects[0].value;
				}

				//now determine the default name fields and label for this object if needed
				if (!resourceObject.displayField) {
					$scope.settings.sourceTemplate.getObjectInfo(
						resourceObject.object,
						saveResourceObjects
					);
				} else {
					saveResourceObjects(false);
				}
				function saveResourceObjects(data) {
					if (data) {
						var fields = data.fields;
						resourceObject.objectDisplay = data.labelPlural;
						for (var i = 0; i < fields.length; i++) {
							if (fields[i].nameField) {
								resourceObject.displayField = fields[i].name;
								resourceObject.searchField = fields[i].name;
								break;
							}
						}
					}

					// Update resource objects with newly created resource object this is an array of objects
					$scope.settings.resourceObjects = [resourceObject];

					// Add resource object to new resource objects
					newResourceObjects[objectId] = resourceObject;

					// Update the current selected source with new resource objects list
					$scope.settings.selectedSource.resourceObjects =
						newResourceObjects;

					manageConfig.updateSourceObject(
						path,
						'resourceObjects',
						newResourceObjects
					);
				}
			}
		}

		function testConfig() {
			var connection = getTestConnection($scope.settings.selectedSource);
			// Test for -dbnames
			var query = fmxj.fileNamesURL();
			$scope.serverTest = {};

			// Post Query - begin test
			fmxj.postQueryFMS(
				query,
				writeResultDBNames,
				writeDownload,
				connection.relay
			);
		}

		function testConfigPart2() {
			var connection = getTestConnection($scope.settings.selectedSource);
			// Test for layout name
			var query = fmxj.layoutNamesURL(connection.file);
			// Post Query - bengin test
			fmxj.postQueryFMS(
				query,
				writeResultLayoutNames,
				writeDownload,
				connection.relay
			);
		}

		function testConfigPart3() {
			var connection = getTestConnection($scope.settings.selectedSource);
			// Test for field names
			var query = fmxj.layoutFieldsURL(
				connection.file,
				connection.layout
			);
			// Post Query - bengin test
			fmxj.postQueryFMS(
				query,
				writeResultFieldNames,
				writeDownload,
				connection.relay
			);
		}

		// End functions for displaying our tests of FileMaker Server configs via fmxj

		// Open tokens popover
		function manageTokens(e) {
			var config = seedcodeCalendar.get('config');

			var template = 'tokens/tokens';
			var tokens = {};

			var popover = {
				controller: 'TokensCtrl',
				// target: e.currentTarget,
				container: $('body'),
				type: 'modal', // modal or popover
				// destroyOnScroll: environment.isPhone ? false : true,
				// direction: 'auto',
				// width: 250,
				// positionX: targetElementRect.right - 15,
				// positionY: targetElementRect.top + targetElementRect.height / 2,
				data: tokens,
				dataTitle: 'tokens',
				// destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/settings/' +
					template +
					'.html\'"></div>'
			);
		}
	}
})();
